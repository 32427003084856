
.gallery {
	width: 100%; 
	min-height: 160px;
	display:flex;
    position:relative;    
    .pe-prev-button-container {
        position:absolute;
        top: calc( 50% - 25px);
        width: 50px;
        height: 50px;
        border-radius: 50px;
        background-color: #FFFFFF;
        display:flex;
        justify-content: center;
        align-items: center;
        color: #656363;
        font-size: 22px;
        cursor: pointer;
        transition: all 200ms ease-out;
        left: 10px;
        z-index: 1;
        &:hover {
            color: #111;
            font-size: 25px;
            transform:translateX(-3px);
        }
        &:active {
            background-color: #eee;
            font-size: 22px;
        }
    }
    .pe-next-button-container {
        position:absolute;
        top: calc( 50% - 25px);
        width: 50px;
        height: 50px;
        border-radius: 50px;
        background-color: #FFFFFF;
        display:flex;
        justify-content: center;
        align-items: center;
        color: #656363;
        cursor: pointer;
        font-size: 22px;
        right: 10px;
        transition: all 200ms ease-out;
        z-index: 1;
        &:hover {
            color: #111;
            font-size: 25px;
            transform:translateX(3px);
        }
        &:active {
            background-color: #eee;
            font-size: 22px;
        }
    }
}
.gallery-item {
	//min-height:160px;  
}
.prev {
	position: absolute;
	top: 50%;
	left: 0px;
    z-index: 1;
}
.next {
	position: absolute;
	top: 50%;
	right: 0px;
    z-index: 1;
}