.main-container {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  position:relative;
}

.window {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.all-pages-container {
  height: 100%;
  display: flex;
  transition: translate;
  transition-property: transform;
  /* transition-duration: 300ms; */
  transition-timing-function: ease-in-out;
}

.carousel-arrow {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  top: auto!important;
  bottom: calc(50% - 25px);
  z-index: 4;
  display: flex!important;
  justify-content: center;
  align-items: center;
  color: var(--force-contast-color); 
  font-size: 2.0em; 
  opacity: .75;
  &:hover {
    opacity: 1;
  }
  &.prev {
    left:0;
  }
  &.next {
    right: 0;
  }
}