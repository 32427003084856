.pe-cp-buttons {
    text-align: center;
    width: 100%;
    white-space: nowrap;
}
  
.pe-cp-button {
    font-size: 15px;
    position: relative;
    z-index: 1;
    margin: 0 calc(15px * .25);
    background: transparent;
    display: inline-block;
    border-radius: 20px;
    border: none;
    color: inherit;
    border: 1px solid #2a2d32;
    padding: calc(15px * .8) calc(15px * 1.6);
    cursor: pointer;
    appearance: none;
    transition: all .2s ease-out;
}
.pe-cp-button:before {
    content: '';
    position: absolute;
    height: 100%;
    left: calc(15px * .7);
    top: 0;
    width: 1px;
    background: #6a6d72;
    transform: scaleY(0);
    transition: transform .2s ease-in-out;
}
    
.pe-cp-button:hover {
    border: 1px solid #3a3d42;
}
    
.pe-cp-button.active {
    background: #3a3d42;
    border: 1px solid #6a6d72;
}      
.pe-cp-button + .button.active {
    border-radius: 0 20px 20px 0;
    margin-left: calc(15px * -2);
    padding-left: 1.6em;
        border-left: none;
}        
.pe-cp-button:before {
    transform: scaleY(1);
}

.scalar-imgage-radio-label
{
	display:flex;
	flex-direction:column;
	padding:10px;
	position:relative;
}
.scalar-imgage-radio-img
{
	height: 130px;
	z-index: 1;
    margin-right: auto;
    margin-left: auto;
}
.scalar-imgage-radio-title
{
	margin: 0;
    z-index: 2;
    text-align: center;
    font-size: .9rem; 
    margin-bottom: 0.25rem;
}
.scalar-imgage-radio-description
{
	font-style:italic;
	font-size:.7rem; 
    max-width: 180px;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
} 
.scalar-imgage-radio-label { 
    justify-content: start!important;
    align-items: start!important;
}
.scalar-imgage-radio-label:before input[type=radio]::checked
{
	content:"";	
	position:absolute;
	width:100%;
	height:100%;
	background-color:#FFFFFF;
}


.scalar-imgage-radio-img,
.scalar-imgage-radio-label
{
    transition: 200ms;
}
.scalar-imgage-radio-img:hover,
.scalar-imgage-radio-label:hover
{
    opacity: 1!important;
}