
$settings-color : #6b3f2d27;
$rating-color : #aac1ac27;
$descriptions-color : #bd8b9e27;
$manage-color : #796ab327;
$team-color : #6a8eb327;
$diary-color : #b3aa6a27;

.tab-light-head > .bp3-tab
{
	font-size: 23px!important;
}
.tab-light-head > .bp3-tab-list 
{
    background: #FFF;
	padding:1rem;
	
}
.pagination
{
	margin:0!important;
}
[type="button"]:focus,
[role="tab"]:focus,
input[type]:focus,
input[type]:focus,
textarea:focus,
[type="button"]:active,
[role="tab"]:active
{
	-webkit-box-shadow: 0 0 0 0 #FFFFFF!important;
	box-shadow: 0 0 0 0 #FFFFFF!important;
	outline: none!important;
	
}
html, body, .m, #root
{
	height:100%;
    display: flex;
    flex-direction: column;
    flex-grow: 100;
}
header, footer
{
	display: -webkit-flex;
	display:    -moz-flex;
	display:     -ms-flex;
	display:         flex;
	background:transparent;
	flex-direction:row;
	flex-grow:0;
}
main
{
	display: -webkit-flex!important;
	display:    -moz-flex!important;
	display:     -ms-flex!important;
	display:         flex!important;
	flex-direction:row!important;
	flex-grow:20!important;
	flex-wrap: wrap!important;
    justify-content: center;
}
textarea
{
	border:1px solid transparent!important;
}
textarea:focus
{
	border:1px solid #00000033!important;
}
.site-card
{
	padding:20px;
	border: 1px solid #00000011;
	background-color: #FFF;
	margin: 4px;
	height: 320px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor:pointer;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	position: relative;
	perspective: 1000px;
	transform: perspective(1000px) translateY(0);
	transform-style: preserve-3d;
	transition: transform .8s ease-in-out;
}
.site-card-cont:nth-of-type(2n) .site-card 
{
	background-color: #212d36;
	color: #FFF;
}

.site-card-cont:hover .site-card
{
    transform: rotateY(179deg);
	z-index: 2;  
}
.site-card-cont:hover .site-card .text-overlay
{
	opacity: 0;
	transform-style: preserve-3d;
	z-index: 1;
}
.site-card-cont:hover .site-card .purchase-button-container
{
	opacity: 1;
}
.text-overlay
{
	transform: perspective(1000px) translateY(0) translateZ(80px);
	transition: transform .8s ease-in-out, opacity 1s ease;
	pointer-events: none;
	width: 100%;
	perspective: inherit;
	padding-top: 0px;	
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pefest-admin-step
{
	display: flex;
	padding: 30px;
	padding-left: 45px;
    margin-right: -15px;
    margin-left: -15px;

}
.pefest-admin-step:nth-of-type(2n)
{
	background-color: #d4d8c5;
}
.pefest-admin-step-label
{
	position:absolute;
	left: -30px;
	width:60px;
	height:60px;
	background: #75ce66;
	border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 900;
    color: #FFF;
    box-shadow: 0 0 0 4px #ffffff, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%);
}
.pefest-admin-step-content
{
	position: relative;
	width: 100%;
    margin-left: 5px;
    background: #ffffff;
    border-radius: 0.25em;
    padding: 1.6em;
    box-shadow: 0 3px 0 #00000014;
}
.pefest-admin-step-content:before
{
	content: "";
    position: absolute;
    top: 25px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #ffffff;
}

.purchase-button-container
{ 
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	left: 0;
	top: 0;
	transform: perspective(1000px) rotateY(179deg) translateY(0) translateZ(80px);
	opacity: 0;
	z-index: -1;
	transition: transform .8s ease-in-out, opacity 1s ease;
	width: 100%;
	height: 100%; 
}
.site-card-cont:hover .site-card .purchase-button-container
{
	opacity: 1;
}

.site-card-cont .site-card.new-site
{
	background-color: #eff4f7;
	color: #7b8790;
}
.wp-fest-admin-btn
{
	width: 180px;
    height: 80px;
    padding: 20px!important;
    display: flex!important;
    white-space: break-spaces!important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.1!important;
}
.wp-fest-api-blogid
{
	position: absolute;
	top: -25px;
	left: -22px;
	padding:3px;
	background:#444;
	color:#EEE;
	width:27px;
	height:27px;
	display:flex;
	justify-content: center;
	align-items: center;
	font-size:11px;
}
.wp-fest-api-name
{
    font-weight: 700;
    font-size: 18px;
    padding: 5px 30px;
    text-align: center;
    line-height: 1.1;
}
.wp-fest-api-domain
{
	font-size:12px;
}
.wp-fest-api-type
{
	position:absolute;
	bottom:2px;
	width:100%;
	text-align: center;
	height: 22px;
}
.wp-fest-api-thumb
{
	width:100px;
	height:100px;
	background-size:cover;
	background-repeat: no-repeat; 
	position:relative;
	display:flex;
	justify-content: center;
	align-items: center;
}
.wp-fest-api-thumb::after
{
	content: "";
	position:absolute;
	top: 0px;
	left: 0px;
	width: 100px;
	height: 100px;
	border-radius:100%;
	background-color: #FFFFFF;
}
.wp-fest-api-thumb::before
{
	content: "";
	position:absolute;
	top: 25px;
	left: 25px;
	width: 50px;
	height: 50px;
	background-size:cover;
	background-repeat: no-repeat;
	z-index:4;
}
.bp3-input
{
	min-height:40px;
}
.site-card-cont .site-card.new-site .wp-fest-api-thumb::after
{
	background-color: transparent;
	border: 1px solid #00000011;
}

.public-DraftEditor-content
{
	padding:10px;
	height: 250px;
}
.rdw-editor-wrapper
{
    border: 1px solid #00000025;

}
.wpfest-admin-example,
.wpfest-admin-example .bp3-tab-panel
{
	width:100%;
}
.wpfest-admin-example .bp3-tab 
{
	width: 260px!important;
    color: #FFF!important;
}
.list-group-item:hover
{
	background: var(--dark-hover-color)!important;
}
.lightner .bp3-input-group,
.lightner .page-item .page-link
{
	color:#FFF!important;
}

.table-btn
{
	width: calc(100% - 10px);
	cursor: pointer;
    text-transform: uppercase;
    border-radius: 50rem!important;
    font-size: .8rem;
    color: #ff6e4a;
    background-color: transparent;
    background-image: none;
    border-color: #ff6e4a;
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.table-hover2 tr:hover .table-btn
{
	background-color: #ff6e4a;
	color:#FFFF;
}
.table-hover2 tr:hover > td
{
	background-color:#00000011;
}
.table-hover2 tr.opened td
{
	background-color: #ff6e4a;
	border-top-width: 0;
	border-bottom-width: 0;
}
.table-hover2 tr.opened,
.table-hover2 tr.opened td,
.table-hover2 tr.opened td div,
.table-hover2 tr.opened td span
{
	
	color:#FFFF;
}
.table-hover2 tr.opened  .table-btn
{
	background-color: #FFFF;
	color: #ff6e4a;
}
.open-bordered
{
	border: 9px solid #ff6e4a;
}
@keyframes wobble 
{
	0% 
	{
		-moz-transform: translateX(0px) translateY(100px);
		-webkit-transform: translateX(0px) translateY(100px);
		-o-transform: translateX(0px) translateY(100px);
		-ms-transform: translateX(0px) translateY(100px);
		transform: translateX(0px) translateY(100px);
	}
	100% 
	{
		-moz-transform: translateX(0px) translateY(0px);
		-webkit-transform: translateX(0px) translateY(0px);
		-o-transform: translateX(0px) translateY(0px);
		-ms-transform: translateX(0px) translateY(0px);
		transform: translateX(0px) translateY(0px);
	} 
}
@keyframes wobble2 
{
	0% 
	{
		-moz-transform: translateX(100px) translateY(0px);
		-webkit-transform: translateX(100px) translateY(0px);
		-o-transform: translateX(100px) translateY(0px);
		-ms-transform: translateX(100px) translateY(0px);
		transform: translateX(100px) translateY(0px);
	}
	100% 
	{
		-moz-transform: translateX(0px) translateY(0px);
		-webkit-transform: translateX(0px) translateY(0px);
		-o-transform: translateX(0px) translateY(0px);
		-ms-transform: translateX(0px) translateY(0px);
		transform: translateX(0px) translateY(0px);
	} 
}
@keyframes wobble3 
{
	0% 
	{
		-moz-transform: scale(0.92);
		-webkit-transform: scale(0.92);
		-o-transform: scale(0.92);
		-ms-transform: scale(0.92);
		transform: scale(0.92);
		opacity:0;
	}
	20% 
	{
		-moz-transform: scale(1.05);
		-webkit-transform: scale(1.05);
		-o-transform: scale(1.05);
		-ms-transform: scale(1.05);
		transform: scale(1.05);
		opacity:1;
	}
	100% 
	{
		-moz-transform: scale(1.0);
		-webkit-transform: scale(1.0);
		-o-transform: scale(1.0);
		-ms-transform: scale(1.0);
		transform: scale(1.0);
		opacity:1;
	} 
}
#msgc
{
	display:flex;
	flex-direction: column;
	position:fixed;
	bottom:10px;
	right:10px;
	z-index:10000;
	width:400px;
	pointer-events:none;
}
.msg
{
	position:relative;
	align-self: center;
	--bottom:10px;
	--right:10px;
	padding:20px;
	margin:5px;
	padding-right:40px;
	max-width:400px;
	background-color:rgba(0,0,0,0.75);
	color:#FFF;
	animation-name: wobble;
    animation-duration: .5s;
}
.msg .close
{
	color: #fdfdfd;
	position: absolute;
    right: 10px;
    top: 18px;
}
.wobble2
{
	animation-name: wobble2;
    animation-duration: 1s;
}
.mcard
{
	width:200px;
	height:200px;
	display:flex;	
    justify-content: center;
    align-content: center;
    align-items: center;
	flex-direction: column;
}
.hidden
{
	display:none!important;
}
.fl
{
	margin: 0 -5px 0 -5px!important;
}
.fla
{
    -webkit-box-shadow: -2px 3px 3px 1px #00000055;
    box-shadow: -2px 3px 3px 1px #00000055;
}
.uabtn
{
    display: flex;
	height: 140px;
	--width:150px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
	line-height: 1;
}
.uabtn > span,
.uabtn > div
{
	margin:0 10px;
	text-align:center;
}
.pointer
{
	cursor:pointer;
}

.color-display
{
    width: 16px;
    height: 14px;
    position: relative;
    margin: 4px;
    outline-color: #FFF;
    outline-width: 6px;
    outline-style: solid;
    outline-offset: 0px;
    -webkit-box-shadow: 0 0 0 7px rgba(0,0,0,0.075), 5px 5px 5px 1px rgba(0,0,0,0.25);
    box-shadow: 0 0 0 7px rgba(0,0,0,0.075), 5px 5px 5px 1px rgba(0,0,0,0.25);
}
.square
{
	width: 210px;
    height: 210px;
	display: flex;
	flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
	justify-content: center;
    -webkit-box-pack: center;
	 align-items: center;
    -webkit-box-align: center;	
    padding: 20px;
    text-align: center;
}
.member_span 
{
    padding: 3px 6px;
    margin: 0 2px;
    background-color: rgba(0,0,0,.075);
    font-size: .75em;
    border: 1px solid rgba(0,0,0,.125);
    cursor: pointer;
}

.ggreen
{
	background-color:#88fd0d!important;
}

.cat_color_label
{
	position: absolute;
    top: 8px;
    left: 13px;
    width: 20px;
    height: 20px;
    background: #086d08;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-shadow:
		inset 0 3px 9px 0 rgba(0,0,0,0.5),
		0 1px 1px 0 rgba(255,255,255,0.75),
		0 -1px 1px 0 rgba(0,0,0,0.75);
	box-shadow:
		inset 0 3px 9px 0 rgba(0,0,0,0.5),
		0 1px 1px 0 rgba(255,255,255,0.75),
		0 -1px 1px 0 rgba(0,0,0,0.75);
	-webkit-transition: all 300ms ease-out;
	-moz-transition: all 300ms ease-out;
	-ms-transition: all 300ms ease-out;
	-o-transition: all 300ms ease-out;
	transition: all 300ms ease-out;
}
.media_button
{
	color: #555;
    border-color: #ccc;
    background: #f7f7f7;
    box-shadow: 0 1px 0 #ccc;
    vertical-align: top;
	display: inline-block;
    text-decoration: none;
    font-size: 13px;
    line-height: 26px;
	cursor: pointer;
    border-width: 1px;
    border-style: solid;
    -webkit-appearance: none;
    border-radius: 3px;
    white-space: nowrap;
    box-sizing: border-box;
	padding:10px;
}
.media_button:active
{
	-webkit-box-shadow:inset 0 5px 5px 0 rgba(0,0,0,0.25);
	box-shadow:inset 0 5px 5px 0 rgba(0,0,0,0.25);
	padding:11px 10px 9px 10px;
}
.dropdown-item.active small,
.dropdown-item:active small
{
	color:#CCC!important;
} 
.dropdown-item
{
	position:relative;
	text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
	padding:10px;
	border-top:1px dotted #777;
}
.dropdown-toggling
{	
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
	padding-right:30px;
}
.dropdown-toggling::after 
{
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
	position: absolute;
    right: 10px;
    top: 45%;
}
.toons
{
	border-radius:2px;
	-webkit-border-radius:2px;
	font-size:0.75rem;
	padding:0px 8px;
	margin:2px;
	border:1px solid #FFFFFF75;
    display: inline-flex;
}
.xbox {
	width: 120px;
	height: 120px;
}
.xbox,
.xbox div {
	margin: 0 auto;
	padding: 3px;
	border: 4px solid transparent;
	border-top-color: rgb(77, 72, 72);
	border-radius: 100%;
	animation: rotate linear 2.5s infinite;
	-webkit-animation: rotate linear 2.5s infinite;
}
.xbox div {
	width: 100%;
	height: 100%;
}
@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@-webkit-keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.mycro_icon
{
    display: inline-block;
    background-position: 4px center;
    background-size: 28px;
    background-repeat: no-repeat;
    padding-left: 40px;
    padding-top: 6px;
    padding-right: 10px;
    cursor: pointer;
    opacity: 0.9;
    color: #6f0c0c!important;
    font-size: 0.8rem;
	width:25px;
	height:25px;
	vertical-align:middle;
}
.bp3-progress-bar.new,
.new .bp3-progress-meter
{
	height:75px; 
	
}
.bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab
{
	flex: 0 0 auto!important;
}
.uabtn-cont
{
	min-height:100%;
}
.DayPickerInput
{
	display:block;
	width: 100%;
}
.DayPickerInput input
{
	width:100%;
	display:block;
	padding:5px 10px;
}

.wpfest-admin-section
{
	position: relative; 
	border-bottom:3px dotted #00000030; 
	display:flex; 

}.pe-fest-admin-logo {
    width: 45px;
    min-width: 45px;
    height: 45px;
    /* float: left; */
    background-size: cover;
    // background-image: url(/static/media/pe_FEST_logo.ebc48aab.svg);
    margin-right: 2px;
    margin-top: 3px;
}
.wpfest-admin-section .head-title,
.body .head-title
{
	justify-self: end;
	margin-top: auto;
	width: 50%;
	margin: auto 0 0 0;
	z-index:200;
}
.wpfest-admin-section .head-title .head-title-title,
.body .head-title .head-title-title
{
    font-size: 2.7rem;
    font-weight: 100;
    color: #FFFFFF;
    width: 50%;
    background-color: #872525;
    padding: 0 25px 5px 25px;
}
.wpfest-admin-section .head-title .head-title-descr,
.body .head-title .head-title-descr
{
	font-size: .9rem;
    font-weight: 100;
    background: #293742b8;
    padding: 20px;
    color: #FFF;
}
.wpfest-admin-section .feature
{
	position:relative;
	display: flex;
	flex-direction: column;
	align-items: center; 
	background-color: #9ec4a9;
	padding: 5px 0;
}
.wpfest-admin-section .feature .thumbn
{
	width:160px;
	height:160px; 
	background-size: 35px 35px;
	background-position: center;
	background-repeat: no-repeat;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position:absolute;
	top:  calc(50% - 80px);
	left: calc(50% - 80px);
}
.wpfest-admin-section .feature .pp
{
	width:100%;
	height:auto;
}





.critery-title
{
	font-size: 14px;
    font-weight: 100; 
}
.descr-label
{
	text-transform: lowercase !important;
	font-size: 13px; 
    font-weight: 100;
}
.fest-id
{
	font-size: 9px;
    font-weight: 900;
	width:100px;
	height:20px; 
	position:relative;
	display:flex;
	justify-content: center;
	align-items: center;
	opacity:0.5;
}
.fest-id::before
{
	content: "";
    width: 100px;
    height: 30px;
    border: 1px solid #00000052;
    position: absolute;
	border-radius: 2px;
}


.two-side-card
{
	padding: 40px 45px;
	border: 1px solid #00000011;
	background-color: #FFF;
	margin: 4px;
	height: 390px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor:pointer;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	position: relative;
	perspective: 1000px;
	transform: perspective(1000px) translateY(0);
	transform-style: preserve-3d;
	transition: transform .8s ease-in-out;
}

.two-side-card-cont:nth-of-type(2n) .two-side-card
{
	background-color: #212d36;
	color: #FFF;
}
.two-side-card-cont:nth-of-type(2n) .purchase-button-container
{
	padding:0;
}
.list-group-item
{
	border-bottom-color:rgba(238, 238, 238, 0.046)!important;
}
.two-side-card-cont:nth-of-type(2n) .purchase-button-container .list-group-item,
.two-side-card-cont:nth-of-type(2n) .purchase-button-container .list-group-item:hover
{
	background-color:#212d36!important;
	border-bottom-color:rgba(238, 238, 238, 0.046)!important;
	
}
.two-side-card-cont:nth-of-type(2n+1) .purchase-button-container .text-data
{
	color:#111;
}
.two-side-card-cont:nth-of-type(2n) .purchase-button-container .text-data
{ 
	color:#DDD; 
}

.two-side-card-cont:hover .two-side-card
{
    transform: rotateY(179deg);
	z-index: 2;  
}

.two-side-card .text-overlay
{
	transform: perspective(1000px) translateY(0) translateZ(50px);
	transition: transform .8s ease-in-out, opacity 1s ease;
	pointer-events: none;
	width: 100%;
	height: 100%;
	perspective: inherit; 
    display: flex;
    flex-direction: column;
    align-items: center;
}
.two-side-card-cont:hover .two-side-card .text-overlay
{
	opacity: 0;
	transform-style: preserve-3d;
	z-index: 1; 

}
.two-side-card-cont .text-overlay .two-side-card-img
{
	background-size: cover; 	
    background-position: center;
	position: relative;
}
.two-side-card-cont:hover .two-side-card .purchase-button-container
{
	opacity: 1;
}

.two-side-card-cont:hover .two-side-card .purchase-button-container
{
	opacity: 1;
}

.two-side-card-cont .two-side-card.new-site
{
	background-color: #eff4f7;
	color: #7b8790;
}
.two-side-card-cont .text-overlay .two-side-card-title
{
	padding:20px;
}
.two-side-card-cont .two-side-card.new-site .wp-fest-api-thumb::after
{
	background-color: transparent;
	border: 1px solid #00000011;
}

.layout-header
{
	
}
.member
{
	border:solid 13px #111;
	border-right-width:12px;
	border-bottom-width:12px;
	background-color:transparent;
	background:url(../img/grad2.png) repeat no-repeat;
	background-size: cover;
	padding:30px;
	margin:20px 2px;
	position:relative;
	color:#111;
	cursor:pointer;
	-webkit-box-shadow: 6px 6px 24px 2px rgba(0,0,0,0.5),
	1px 1px 1px 1px rgba(0,0,0,1),
	-1px -1px 1px 1px rgba(255,255,255,0.5),
	inset 2px 2px 1px 0px rgba(255,255,255,0.5),
	inset -2px -2px 1px 0px rgba(0,0,0,0.5);
    box-shadow: 6px 6px 24px 2px rgba(0,0,0,0.5), 
	1px 1px 1px 1px rgba(0,0,0,1),
	-1px -1px 1px 1px rgba(255,255,255,0.5),
	inset 2px 2px 1px 0px rgba(255,255,255,0.5),
	inset -2px -2px 1px 0px rgba(0,0,0,0.5);
	text-shadow: 1px 1px 1px rgba(255,255,255,0.75);
	-webkit-transition: opacity 2000ms ease-out;
	-moz-transition: opacity 700ms ease-out;
	-ms-transition: opacity 700ms ease-out;
	-o-transition: opacity 700ms ease-out;
	transition: opacity 700ms ease-out;
	
	.member_title {
		font-size:25px;
		text-align:center;
		position:absolute;
		width:100%;
		height:100%;
		top:50%;
		left:0;
		margin-top:-22px;
		font-weight:900;
	}
	&:hover {
		background-color:rgba(0,0,0,0.125);
		text-shadow: 1px 1px 1px rgba(255,255,255,0.66);
		opacity:1;
		-webkit-transition: opacity 0ms ease-out;
		-moz-transition: opacity 0ms ease-out;
		-ms-transition: opacity 0ms ease-out;
		-o-transition: opacity 0ms ease-out;
		transition: opacity 0ms ease-out;
		.member_title {
			margin-top:-23px;
			color:#000;
		}
		.xperts
		{
			background:#c33205;
		}
	}
	&.active {
		background-image:url(../img/grad.png) repeat no-repeat;
		background-color:rgba(0,0,0,0.25); 
		-webkit-box-shadow: 6px 6px 24px 2px rgba(0,0,0,0.5), 
		1px 1px 1px 1px rgba(0,0,0,1),
		inset 2px 2px 2px 0px rgba(255,255,255,0.5),
		inset -4px -4px 2px 0px rgba(0,0,0,0.75);
		box-shadow:  6px 6px 24px 2px rgba(0,0,0,0.5), 
		1px 1px 1px 1px rgba(0,0,0,1),
		inset -2px -2px 2px 0px rgba(255,255,255,0.5),
		inset 2px 2px 2px 0px rgba(0,0,0,0.75);
		text-shadow: 1px 1px 1px rgba(255,255,255,0.25);
		.member_title {
			margin-top:-21px; 
		}
	}
	&.no-experts {
		border-color: #5F6B7C ;
		.member_title {
			color: #5F6B7C ;
		}
	}
} 

.noselect,
[data-class="noselect"]
{
	opacity:0.5;
}
.xperts
{
	position:absolute;
	bottom:-15px;
	right:-14px;
	color:#FFF;
	background:#de6f00;
	padding:0 7px;
	font-size:14px;
} 
.pe-multistep-dialog-step
{
	padding: 15px;
	min-height: 450px;
	height: 450px;
}
.btn-stretch
{
	display:flex;
	justify-content:stretch;
	align-items:center;
}
.btn-stretch .bp3-button-text
{
	display:flex;
	width:100%;
	align-items:center;
}
.grey,
.grey2:nth-child(even)
{
	background-color:rgb(0 0 0 / 2%);
}
.grey2:nth-child(odd)
{
	background-color:#00000021;
}
.grey2.half-opacity
{
	color:#666;
	background-color:rgba(0,0,0,0.05);
}
.grey3:nth-child(even)
{

}
.grey3:nth-child(odd)
{
	background-color:rgba(0,0,0,0.125);
}
.darkgrey
{
	background-color:rgba(0,0,0,0.45);
}
.transparent
{
	background-color:rgba(0,0,0,0.0);
} 

.hideColor
{
	color:rgba(0,0,0,0);
}
.card-img
{
	width:100%;
	height:100px;
	background-size:cover;
	background-position:center;
	border-bottom: 8px solid #111;
	background-color:#CCC;
	opacity:0.9;
	position: relative;
}
.card-trumb
{ 
	height:300px;
	background-size:cover;
	background-position:center;
    border: 1px solid rgba(255,255,255,0.75);
	position:relative;	
    display: flex;
    justify-content: center;
}
.member-menu-btn
{
	width:100%;
}

.card-icons
{
	position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    width: 100%;
}
.card-icon
{
	width: 60px;
    height: 60px;
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding: 12px;
    background-color: #EEE;
    border-radius: 2px;
	-webkit-transition: all 400ms ease-in;
	-moz-transition: all 400ms ease-in;
	-ms-transition: all 400ms ease-in;
	-o-transition: all 400ms ease-in;
	transition: all 400ms ease-in;
	border-radius: 62px;
    -webkit-box-shadow: 0 3px 1px -2px rgba(0,0,0,.75), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    box-shadow: 0 5px 8px 0px rgba(0,0,0,.75), 0 2px 6px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
	float:right;
	margin:3px;
}	

.card:hover  .card-img
{
	opacity:1;
}
.card:hover .card-id
{
	background:darkred;
}
.card-id
{
	position:absolute;
	z-index:2;
	background:#14172b;
	color:#FFF;
	padding:2px 6px;
	font-size: 12px;
	font-weight:900;
	border-radius: 2px;    
	left: 0;
}

.card .list-group-item, 
#card .list-group-item 
{
    background-color: transparent!important;
    border-color: rgba(0,0,0,0.05)!important;
	padding:0!important;
	text-align:left;
	vertical-align:middle;
}
.card .list-group-item>.discr, 
#card .list-group-item >.discr
{
	float: left;
	padding:10px 20px;
	font-size:0.9em;
}
.card .list-group-item>.data, 
#card .list-group-item>.data
{
	float:right;
	padding:10px 20px;
	font-weight:500;
	font-size:0.9em;
}
.card .list-group-item>.data.selected, 
#card .list-group-item>.data.selected
{
	background-color:rgba(0,0,0,0.125);
}


.fest-card-track-label
{
    position: absolute;
    bottom: 2px;
    display: flex;
    align-items: center;
    left: 0px;
    padding: 1px 12px;
    background-color: #b5b8cc;
    color: #000;
    font-size: 12px;
    cursor: pointer;
	transition: all 200ms ease-out;
}
.card:hover .fest-card-track-label
{
	background:#cbccd3;
}
.critery_cell,
.critery_cell2,
.critery_cell3
{
	padding: 16px;
	position:relative;
   
}
.row_cell:nth-of-type( 2n )::before 
{
	content:"";
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color: #00000011;
}
.critery_cell
{
	border-bottom: 1px solid rgba(255,255,255,0.5);
	align-items: center;
}
.critery_cell3
{
	padding: 30px 25px!important;
}
.bl-20
{
	border-left: 20px solid #adadad21;
}

.fmRuTitle
{
	font-size:24px;
	font-weight:500;
}
.fmRuTitle>small
{
	font-size:16px;
	font-weight:100;
	opacity:0.7;
}

.fmRU_button,
.fmRU_button.light,
.fmRU_button.xl
{
	min-width:48px;
	width:48px;
	height:48px;
	-webkit-border-radius: 45px;
	border-radius: 45px;
    position: relative;
	font-size:24px;
	font-family:FontAwesome;
	background-color:rgba(0,0,0,0.05);
	border:1px solid rgba(0,0,0,0.1);
	-webkit-transition: all 300ms ease-in;
	-moz-transition: all 200ms ease-in;
	-ms-transition: all 200ms ease-in;
	-o-transition: all 200ms ease-in;
	transition: all 200ms ease-in;
	cursor:pointer;
	float:left;
	margin-left:12px;
}
.fmRU_button:hover,
.fmRU_button.light:hover,
.fmRU_button.xl:hover
{
	background-color:rgba(0,0,0,0.1);
	border:1px solid rgba(0,0,0,0.125);
	text-decoration:none!important;
}
.fmRU_button:active,
.fmRU_button.light:active,
.fmRU_button.xl:active
{
	-webkit-box-shadow:inset 0 5px 5px 0 rgba(0,0,0,0.25);
	box-shadow:inset 0 5px 5px 0 rgba(0,0,0,0.25);
}
.fmRU_button.light
{
	background-color:rgba(255,255,255,0.05);
	border:1px solid rgba(255,255,255,0.0125);
}
.fmRU_button.light:hover
{
	background-color:rgba(255,255,255,0.1);
	border:1px solid rgba(255,255,255,0.0125);
}
.fmRU_button.xl
{
	width:36px;
	height:36px;
	-webkit-border-radius: 36px;
	border-radius: 36px;
    position: relative;
	font-size:20px;
}
.fmRU_button > i,
.fmRU_button.xl> i,
.fmRU_button > span,
.fmRU_button.xl> span
{
	content:attr(data-attr);
	position:absolute;
	top:24px;
	left:0;
	margin-top:-15px;
	font-size:30px;	
    display: block;
	text-align:center;
    width: 100%;	
	-moz-transition: all 100ms ease-in;
	-ms-transition: all 100ms ease-in;
	-o-transition: all 100ms ease-in;
	transition: all 100ms ease-in;
	opacity:.7;
}
 
.fmRU_button:hover > i,
.fmRU_button.xl:hover > i,
.fmRU_button:hover > span,
.fmRU_button.xl:hover > span
{
	opacity:1;
}
.fmRU_button:active > i,
.fmRU_button.xl:active > i,
.fmRU_button:active > span,
.fmRU_button.xl:active > span
{
	top:25px;
}
.fmRU_button.xl> i,
.fmRU_button.xl> span
{
	font-size:16px;
}
.fmRU_button.open > i,
.fmRU_button.open.xl> i,
.fmRU_button.open > span,
.fmRU_button.open.xl> span
{
	-ms-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}




.indicator
{
	position: relative!important;
    flex: 0 1 auto!important;
    width: var(--iconoc-width);
    max-width: var(--iconoc-width);
    height: 98px!important;
    margin-right: 3px!important;
    margin-left: 3px!important;
    text-indent: -999px!important;
    cursor: pointer!important;
    background-color: transparent!important;
    padding: 10px 0!important;
    text-indent: 0!important;
    background-color: #e6e6e600!important;
    margin-top: auto;
	transition: width 600ms ease-out;
	transition-delay: 0ms;
}
.indicator.dble {
	transition: width 800ms ease-out;
	transition-delay: 0ms;
	padding-top:0!important;
}
.indicator.closed {
	width: 0;
	min-width: 0 !important;
	margin-left: 0 !important;
    margin-right: 0 !important;
    padding-right: 0!important;
    padding-left: 0!important;
}
.indicator.classic:hover>.n1,
.indicator.active
{
	background-color: transparent;
    color: #146c73;
	opacity:1;
}
.indicator.active:before
{	
	content: "";
    position: absolute;
    bottom: 0;
    width: 90px;
    left: 0px;
    border-bottom: 5px solid #146c73!important;
	
}
.__indicator.classic:hover:before
{	
	content: "";
    position: absolute;
    bottom: 0;
    width: 90px;
    left: 0px;
    border-bottom: 5px solid #146c73!important;
	
}
.indicator::after
{
	content:"";
}
.indicator >.n1
{
    position: absolute;
    top: 71px;
    left: 0;
    width: 100%;
    height: 21px;
    text-align: center;
    font-size: 13px;
    opacity: 0.75;
    color: #444!important;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: .9;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* background-color: #e6e6e6!important; */
}
.indicator >.iconnn
{
	 -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
	opacity:0.5;
}
.indicator.classic:hover >.iconnn,
.indicator:hover >.iconnn
{
	opacity:1;
}
.indicator.active >.iconnn
{
	-webkit-filter: grayscale(0%);
    filter: grayscale(0%);
	opacity:1;
}
.indicator.classic >.iconnn
{
	 -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
	opacity:.75;
}
.indicator >.iconnn > img,
.indicator>svg,
i.iconn
{
    position: absolute;
    top: 0;
    left: 31%;
    margin-left: -15px;
    width: 50px;
	font-size:35px;
}
.indicator.lrg > .n1
{ 
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
}
.honneycombs-popover
{
	position: absolute;
    z-index: 10000;
    top: calc(100% + 2px);
    right: 8px;
    width: 240px;
    background-color: #FFF;
    padding: 0px; 
	overflow:hidden;
	box-shadow: 0 2px 4px 1px rgba(0,0,0,0.1), 0 4px 8px 1px rgba(0,0,0,0.05);
}
.indicator >.iconnn.little > img
{
	top: 17px;
    width: 25px;
    margin-left: -10px;
}
i.iconn>span
{
	position: absolute;
    top: 50%;
    left: 0;
	display:none;
}	
.iconnn
{
	width: 50px;
    position: absolute;
    top: 14px;
    left: 50%;
    opacity: 1;
    margin-left: -25px;
}
.head-iconn
{
	height: 50px;
    position: relative;
    display: inline-block;
    top: 0;
    left: 0;
    opacity: 1;
    margin-left: 0;
    margin-right: 10px;
}
.head-iconn >img
{
	height:50px;
	width: auto;
}
i.iconn:not(.first)>span:after
{
	content: "";
    position: absolute;
    top: 0;
    right: 20px;
    margin-top: -2px;
    width: 45px;
    border: 1px solid rgba(0,0,0,0.125);
}
i.iconn:not(.last)>span:before
{
    content: "";
    position: absolute;
    top: 0;
    left: 50px;
    margin-top: -2px;
    width: 46px;
    border: 1px solid rgba(0,0,0,0.125);
}
 
input[type=checkbox].ganre_checkbox:not(.checked),
input[type=checkbox].ganre_checkbox.checked
{
	position: absolute;
	opacity: 0;
}
input[type=checkbox].ganre_checkbox:not(.checked) + label ,
input[type=checkbox].ganre_checkbox.checked + label 
{
    position: relative;
    padding: 56px 0 0 0;
    height: 50px;
    vertical-align: middle;
    display: table-cell;
    margin-bottom: 2px;
    width: 80px;
    font-size: 10px;
    word-break: break-all;
	text-align:center;
}
/* Оформление первой части чекбокса в выключенном состоянии (фон). */
input[type=checkbox].ganre_checkbox:not(.checked) + label:before ,
input[type=checkbox].ganre_checkbox.checked + label:before 
{
	content: '';
	position: absolute;
	top: -4px;
	left: 20px;
	width: 50px;
	height: 50px;
	border-radius: 3px;
	background: #cdd1da1a!important;
	box-shadow: none!important;
	/*box-shadow: inset 0 2px 3px rgba(0,0,0,.2);*/
}
/* Меняем фон чекбокса, когда он включен. */
input[type=checkbox].ganre_checkbox.checked + label:before 
{
	background: #fefffd!important;
}

input[type=checkbox].ganre_checkbox:not(.checked) + label img,
input[type=checkbox].ganre_checkbox.checked + label img
{
	width:40px;
	height:40px;
	position:absolute;
	top:0px;
	left: 25px;
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
	opacity:0.5;
	-webkit-transition: all 300ms ease-out;
	-moz-transition: all 300ms ease-out;
	-ms-transition: all 300ms ease-out;
	-o-transition: all 300ms ease-out;
	transition: all 300ms ease-out;
}
input[type=checkbox].ganre_checkbox.checked + label img
{
	-webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
	opacity:1;
}
.mcontent
{
	padding:5px 35px;
}

.ganre_title
{
	padding: 0px 20px 1px 10px;
    background: rgba(0,0,0,0.5);
    opacity: 0.8;
    margin: 1px;
    position: relative;
    display: inline-block;
	color:#FFF;
}
.ganre_title:hover
{
	opacity:1;
	/*background:rgba(0,0,0,0.75);*/
}

.socials
{
    font-size: 1.5em;
    border-radius: 4px;
    margin: 3px;
    float: left;
    width: 48px;
    height: 48px;
    text-align: center;
    cursor: pointer;
	color:rgb(88, 97, 105)!important;
}
.socials:hover
{
	background-color:rgba(255,255,255,0.25);
	color:#182129!important; 
}
.role_descr,
.status-descr
{
	padding: 3px 10px; 
	background-color: #e29649;
	color: #FFF;
    display: inline-block;
    border: 1px solid #00000012;
    margin: 4px;
}
.status-descr
{
	background-color: #d16e0c;
} 
.about-content
{
	font-size:1rem;
	line-height:1.6; 
}
.about-content img
{
	max-width:100%;
}	


.close
{
	position: absolute!important;
    right: 0;
	top:0;
    z-index: 5;
    width: 50px!important;
    padding: 15px!important;
}


input[type=radio].radio 
{
	vertical-align: top;
	width: 27px;
	height: 27px;
	margin: 0 3px 0 0;
}
input[type=radio].radio + label 
{
	cursor: pointer;
}
input[type=radio].radio:not(checked) 
{
	position: absolute;
	opacity: 0;
}
input[type=radio].radio:not(checked) + label 
{
	position: relative;
	padding: 0 44px 44px 0;
	margin:3px;
}
input[type=radio].radio + label:active:before ,
input[type=radio].radio:checked + label:before,
.my_button:active
{
    background: #730505;
	color:#FFF;
	-webkit-box-shadow: 
		-1px -1px 1px 0 rgba(0,0,0,1),   
		inset 1px 4px 8px 3px rgba(0,0,0,0.4),  
		1px 1px 1px 0 rgba(255,255,255,.75);
	box-shadow: 
		-1px -1px 1px 0 rgba(0,0,0,1),   
		inset 1px 4px 8px 3px rgba(0,0,0,0.4),  
		1px 1px 1px 0 rgba(255,255,255,.75);
}
input[type=radio].radio + label:active:before 
{
	background: #d20c0c;
}
input[type=radio].radio:not(checked) + label:before ,
.my_button
{
	content: attr(data-hint);
	position: absolute;
	top: -3px;
	left: 0;
	width: 44px;
	height: 44px;
	border-radius: 4px;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#edeff2+0,999999+100 */
	background: #edeff2; /* Old browsers */
	background: -moz-linear-gradient(top,  #edeff2 0%, #999999 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #edeff2 0%,#999999 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #edeff2 0%,#999999 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#edeff2', endColorstr='#999999',GradientType=0 ); /* IE6-9 */
	-webkit-box-shadow: 
		inset -1px -1px 1px 0 rgba(0,0,0,1), 
		1px 4px 6px 0 rgba(0,0,0, .25), 
		inset 1px 1px 1px 0 rgba(255,255,255,.5);
	box-shadow:
		inset -1px -1px 1px 0 rgba(0,0,0,1), 
		1px 4px 6px 0 rgba(0,0,0, .25), 
		inset 1px 1px 1px 0 rgba(255,255,255,.5);
	text-align:center;
	color:#444;
	font-size:17px;
	font-weight:900;
	line-height: 2.5;
}  

input[type=radio].radio_full, 
input[type=checkbox].radio_full 
{
	vertical-align: top;
	width: 100%;
	height: 60px;
	margin: 0 3px 0 0;
}
input[type=radio].radio_full + label, 
input[type=checkbox].radio_full + label 
{
	cursor: pointer;
}
input[type=radio].radio_full:not(checked), 
input[type=checkbox].radio_full:not(checked) 
{
	position: absolute;
	opacity: 0;
}
input[type=radio].radio_full:not(checked) + label, 
input[type=checkbox].radio_full:not(checked) + label 
{
	position: relative;
	padding: 0;
	margin:3px;
    height: 45px;
    width: 100%;
}
input[type=radio].radio_full:checked + label:before,
input[type=checkbox].radio_full:checked + label:before
{
    background:#4a535d;
	color:#FFF;
	-webkit-box-shadow: 
		-1px -1px 1px 0 rgba(0,0,0,1),   
		inset 1px 4px 8px 3px rgba(0,0,0,0.4),  
		1px 1px 1px 0 rgba(255,255,255,.75);
	box-shadow: 
		-1px -1px 1px 0 rgba(0,0,0,1),   
		inset 1px 4px 8px 3px rgba(0,0,0,0.4),  
		1px 1px 1px 0 rgba(255,255,255,.75);
}
input[type=radio].radio_full:not(checked) + label:before,
input[type=checkbox].radio_full:not(checked) + label:before
{
	content: attr(data-hint);
	position: absolute;
	top: -3px;
	left: 0;
	width: 100%;
	height: 44px;
	border-radius: 8px;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#edeff2+0,999999+100 */
	background: #edeff2; /* Old browsers */
	background: -moz-linear-gradient(top,  #edeff2 0%, #999999 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #edeff2 0%,#999999 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #edeff2 0%,#999999 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#edeff2', endColorstr='#999999',GradientType=0 ); /* IE6-9 */
	-webkit-box-shadow: 
		inset -1px -1px 1px 0 rgba(0,0,0,1), 
		1px 4px 6px 0 rgba(0,0,0, .25), 
		inset 1px 1px 1px 0 rgba(255,255,255,.5);
	box-shadow:
		inset -1px -1px 1px 0 rgba(0,0,0,1), 
		1px 4px 6px 0 rgba(0,0,0, .25), 
		inset 1px 1px 1px 0 rgba(255,255,255,.5);
	text-align:left;
	color:#444;
	font-size:15px;
	font-weight:normal;
	line-height: 2.7;
	padding-left:45px;
}
input[type=radio].radio_full:not(checked) + label:after, 
input[type=checkbox].radio_full:not(checked) + label:after 
{
	content: '';
	
}
input[type=radio].radio_full:checked + label:after, 
input[type=checkbox].radio_full:checked + label:after 
{
	opacity: 1;
}

label > .label_title 
{
	position:absolute;
	top: 0px;
	left:50px;
	font-size:15px;
}
input[type=radio].radio_full:checked + label > .label_description 
{
	color:#FFF;
}
input[type=radio].radio_full:checked + label > .label_title 
{
	color:#EEE;
}
label > .label_description 
{
	position:absolute;
	top:18px;
	left:50px;
	font-size:11px;
}

.light-colored 
{ 
    background-color: #5e5d7030; 
}
.display-5
{
	font-size: 2.0rem;
    font-weight: 300;
    line-height: 1.2;
}

.mta
{
	position:absolute;
	bottom:13px;
	right:0px;
	width:57px;
	height:80px;
	padding-top:40px;

	-webkit-transition: all 700ms ease-out;
	-moz-transition: all 700ms ease-out;
	-ms-transition: all 700ms ease-out;
	-o-transition: all 700ms ease-out;
	transition: all 700ms ease-out;
}
.mta.open
{
	width:100%;
}
.mta .fone
{
	position: absolute;
    top: -60px;
    left: 1%;
    width: 98%;
    height: 140px;
    background:transparent;	
	pointer-events: none;	
}
.mta.open .fone
{
	position: absolute;
    top: -60px;
    left: 1%;
    width: 98%;
    height: 140px;
    --background: -moz-linear-gradient(top, rgba(223,223,223,0) 0%, rgba(223,223,223,1) 83%, rgba(223,223,223,1) 98%);
    --background: -webkit-linear-gradient(top, rgba(223,223,223,0) 0%,rgba(223,223,223,1) 83%,rgba(223,223,223,1) 98%);
    --background: linear-gradient(to bottom, rgba(223,223,223,0) 0%,rgba(223,223,223,1) 83%,rgba(223,223,223,1) 98%);
    --filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00dfdfdf', endColorstr='#dfdfdf',GradientType=0 );	
	pointer-events: none;	
}
.molto
{
	width: 240px;
    margin-left: 50px;
}
.moltoSlider
{
	margin-left: 110px;
    margin-right: 10px;
	MARGIN-TOP:15PX;
	background: rgba(0,0,0,0.1);
    border: 1px solid rgba(0,0,0,0.125);
    height: 5px;
	position:relative;
}
.moltoIn
{
	width:1%;
	height:100%;
	background:#FF0000;
}	
.mta .mcph
{
	position:absolute;
    bottom: 4px;
    left: -2px;
	
}
.mta .mcph,
.mta .pl,
.mta .ps
{
	border-radius:3px;
}
.fest-project-route-btn {
	position:relative;
	opacity:.75;
	padding: 6px 14px;
	border: 1px solid transparent;
	color:#111;
} 
.fest-project-route-btn.active-link {
	border: 1px solid rgba(149, 148, 148, .5);
}
.diary_thumbnail
{
	min-width: 125px; 
	height: 125px;
    margin: 20px auto 0 auto;
	display: flex;
	flex-grow: 100;
	justify-content: center;
	background-position: center center;
	background-size: cover;
	margin-right: 16px;
}
.diary_thumbnail img
{
	max-width: 100%;
    max-height: 600px;
    margin: 0 auto;
}
.diary_post
{
	background-color: transparent;
    //max-width: 800px;
    width: 100%;
    padding: 0px;
	padding-top: 35px;
    border-bottom: 5px solid rgba(0,0,0,0.125);
    margin: 20px auto;
	position: relative;
}
.diary_body
{
	padding-right:10px;	
}
.diary_body iframe,
.diary_body img
{
	clear: left;
    display: block;
    margin-bottom: 20px;
    margin-top: 20px;
	max-width:100%;
	height:auto;
}
.diary_title
{
	padding:20px;
	font-weight:100;
	font-size:25px;
}
.diary_footer
{
	padding:15px;
	background-color: rgba(0,0,0,0.05);	
    display: flex;
    align-items: center;
	gap: 10px;
}
.diary_footer span
{
	padding:2px 10px;
}
.diary-link
{
	color: #444!important;
}
.diary-link.light
{
	color: #EEE!important;
}
.search_result
{
	position: absolute;
    width: 100%;
    top: 35px;
    z-index: 100;
}
.menu-switch
{
	position:relative;
	align-self: center;
    margin-right: auto;
	
}
.menu-switch-btn
{
	margin:-15px 20px 0 20px;
	width:55px;
	height:55px;
	-webkit-border-radius:55px;
	border-radius:55px;
	border:1px solid #00000030;
	background-color: #c5c5c5;
	display:inline-flex;
	justify-content:center;
	align-items:center;
	position:relative;
	font-size:0.7rem;
}

.menu-switch-btn.active
{
	border:1px solid #00000060;
	background-color: #959595;	
}
.menu-switch-btn:before
{
	content: attr(data-title);
	position:absolute;
	bottom:-20px;
	left:5px;
	font-size:0.8rem;
	color:#888;
}
.menu-switch-btn.active:before
{ 
	color:#111;
}

.colored .page-link 
{
    color: #f7f7f7!important;
}

.colored .page-item.active .page-link 
{
    color: #FFF;
    background-color: #4c4b4b!important;
    border-bottom: 4px solid transparent;
    box-shadow: inset 0 .25rem .25rem rgba(32,24,15,0.15)!important;
}
.colored .page-item.active .page-link:after 
{
    content: "";
	background-color: transparent;
}
.colored .page-item.disabled .page-link
{
	border-color: transparent;
}
.mine-corner,
.my-track-label,
.mine-corner-box,
.mine-honeycombs-corner 
{
	position:absolute;
	top:0;
	right:0; 
	display:flex;
	justify-content: center;
	align-items: center;
	color:#FFF;
	font-size: 6px;
	letter-spacing: 2px;
	text-transform:uppercase;
	font-weight: 700;
	background-color:#d43f08;
	width: 80px;
	height: 25px;
	text-align: center;
	transform:  rotate(45deg) translateY(47%) translateX( 20%); 
	user-select:none;
}
.mine-corner.d3d
{
	transform: perspective(1044px) translateZ(52px) rotate(45deg) translateY(121%) translateX(18%); 
}
.mine-honeycombs-corner.d3d {
    transform: perspective(1044px) translateZ(52px) rotate(45deg) translateY(181%) translateX(23%);
}
.my-track-label.over-side,
.mine-honeycombs-corner.over-side,
.mine-corner.over-side
{
	z-index: 100;
	transform: scale(130%) rotate(45deg) translateY(-28%) translateX(20%);
}
.my-track-label::before,
.mine-corner::before
{
	content:"";
	position:absolute;
	width:25px;
	height: 25px;
	background-color: #d43f08;
	top:0;
	left:-25px;
	clip-path: polygon(100% 0, 0 100%, 100% 100%);
}
.my-track-label::after,
.mine-corner::after
{
	content:"";
	position:absolute;
	width:25px;
	height: 25px;
	background-color: #d43f08;
	top:0;
	right:-25px;
	clip-path: polygon(0 0, 0 100%, 100% 100%);
}
.my-track-label::before,
.my-track-label::after,
.my-track-label 
{
	background-color: #647a50;
	font-size:8px;
	color:#FFF;
}


.mine-label-stroke,
.my-track-label-stroke, 
.mine-honeycombs-label-stroke
{
	background-color: #d43f08;
    color: #FFF;
    padding: 7px 11px;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    align-items: center; 
	margin-top:5px;
	margin-bottom:5px;
}
.my-track-label-stroke 
{
	background-color: #647a50; 
	color:#FFF; 
}
.mine-honeycombs-label-stroke
{
	color:#5f6b41;
	background-color:#d2d485; 
}
.mine-honeycombs-corner
{
	position:absolute;
	top:0;
	right:0; 
	display:flex;
	justify-content: center;
	align-items: center;
	color:#5f6b41;
	font-size: 8px;
	letter-spacing: 2px;
	text-transform:uppercase;
	font-weight: 700;
	background-color:#d2d485;
	width: 80px;
	height: 25px;
	text-align: center;
	transform:  rotate(45deg) translateY(47%) translateX( 20%);  
	user-select:none;
}
.mine-honeycombs-corner::before
{
	content:"";
	position:absolute;
	width:25px;
	height: 25px;
	background-color: #d2d485;
	top:0;
	left:-25px;
	clip-path: polygon(100% 0, 0 100%, 100% 100%);
}
.mine-honeycombs-corner::after
{
	content:"";
	position:absolute;
	width:25px;
	height: 25px;
	background-color: #d2d485;
	top:0;
	right:-25px;
	clip-path: polygon(0 0, 0 100%, 100% 100%);
}
.mine-corner1
{
	transform: rotate(45deg) translateY(59%) translateX(23%);
}
.mine-corner2
{
	transform: rotate(45deg) translateY(101%) translateX( 12%);
}
.mine-corner-no-verified {
	transform: rotate(45deg) translateY(83%) translateX(18%);
    width: 166px;
    height: 50px;
	position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 11px;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-weight: 700;
    background-color: #882906; 
    text-align: center;  
    user-select: none;
	font-weight: 100;
	&.corner-card-project {
		right: -17px;
	}
	&::before {
		content: "";
		position: absolute;
		width: 50px;
		height: 50px;
		background-color: #882906;
		top: 0;
		left: -49px; 
		clip-path: polygon(100% 0, 0 100%, 100% 100%);
	}
	&::after {
		content: "";
		position: absolute;
		width: 50px;
		height: 50px;
		background-color: #882906;
		top: 0;
		right: -49px;
		clip-path: polygon(0 0, 0 100%, 100% 100%);
	}
	&.admin-card-marathon {
		width: 66px;
		height: 33px;
		width: 66px;
		top: -12px;
		right: -11px;
		background-color: #5b1701;
		font-size: 8px;
		font-weight: 100;
		letter-spacing: 1px;
		&::before {
			content: "";
			position: absolute;
			width: 33px;
			height: 33px;
			background-color: #5b1701;
			top: 0;
			left: -32px; 
			clip-path: polygon(100% 0, 0 100%, 100% 100%);
		}
		&::after {
			content: "";
			position: absolute;
			width: 33px;
			height: 33px;
			background-color: #5b1701;
			top: 0;
			right: -32px;
			clip-path: polygon(0 0, 0 100%, 100% 100%);
		}
	}
}
.mine-corner-box {
	transform: rotate(45deg) translateY(-15%) translateX(26%);
}

.milestones-cont
{
	justify-content: center; 
	margin-bottom: 30px;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		right: -15px;
		bottom: -800px;
		height: 800px;
		width: 15px;
		border-right: 2px solid var(--force-contast-color);
		border-top: 2px solid var(--force-contast-color);
		border-top-right-radius: 15px;
	}
	&:after {
		content:"";
		position:absolute;
		bottom: -2px;
		right: 0px;
		width: 2600px;
		border-top: 2px solid var(--force-contast-color);
	}
}
.milestone-button
{
	padding: 12px 37px 19px;
	border:2px solid var(--force-contast-color);
	background-color:#8a9ba833; 
	opacity:.75;
	border-bottom:none!important;
	font-size: 12px;
	font-weight: bold;
	font-family: Open Sans;
	cursor:pointer;
	margin:0 1px;
	border-radius: 6px 6px 0 0;
	margin-bottom:-2px;
	transition: all 200ms ease-out;
    display: flex;
    align-items: center;
	height: 59px;
}
.milestone-button.milestone-label-primary
{
	background-color: #7ab9ea33;
}
.milestone-button.milestone-label-success
{
	background-color: #7aea9c33;
}
.milestone-button.active
{
	padding: 10px 37px 21px;
	background-color:#e6e6e6;
	color:#111;
	z-index:10;
	opacity:1;
} 
.milestone-button .milestone-button-label
{
	opacity: .85;
    user-select: none;
	transition: all 200ms ease-out;
	font-weight:700;
}
.milestone-button:hover .milestone-button-label,
.milestone-button.active .milestone-button-label
{
	opacity:1;
}
.milestone-attach-cont
{
	display:flex;
	align-items: center;
}
.milestone-attach-cont .milestone-attach-change-btn
{
	opacity: 0;
	font-size:.7rem;
	transition: opacity 200ms ease-out;
}
.milestone-attach-cont:hover .milestone-attach-change-btn
{
	opacity: .6;
}
.honeycombs-label
{
	background-color: #7a9497;
	color:#EEE;
	font-size:12px;
	padding:1px 10px;
}
.my-honeycombs-label, 
.my-project-label
{
	background-color: #d2d485 !important;
	letter-spacing: 2px;
	font-size: 65%;
	font-weight: 500;
	border-radius: 50rem!important;
	text-transform: uppercase !important;
	padding-left: 1.5rem !important;
	padding-right: 1.5rem !important;
	padding-bottom: 0.5rem !important;
	padding-top: 0.5rem !important;
	margin: 0.25rem !important; 
	user-select:none;
}
.my-project-label
{
	background-color: #ef7132 !important;
	color:#FFF !important;	
}

.main-fest-jumbotron-container
{
	background-size: cover;
	background-position: center; 
	background-color: #738091;
	position: relative;
	width: 100%; 
	height:420px;
}
.main-fest-jumbotron
{
	display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}
.bg-cerulian2
{
	background-color: #0F6894;
}
.main-fest-title
{
	margin-bottom: 7px;
}
.main-fest-title,
.main-fest-description
{
	position: relative; 
	z-index: 1;
	margin-left: 10px;
	
}
.main-fest-description
{
	font-size: 17px;
}
.main-fest-title::before,
.main-fest-description::before
{
    content: "";
    position: absolute;
    top: 1px;
    left: -10px;
    width: calc(100% + 20px);
    height: 51px;
    background-color: #293742;
    z-index: -1;
}
.main-fest-description::before
{
	height: 31px;
    top: 0; 
	background-color: #505f6d;
}
.under-track-icon
{
	position: absolute;
    bottom: -37px;
    left: 50%;
    width: 84px;
    height: 84px;
    border-radius: 100%;
    background-color: #000;
    margin-left: -42px;
}
.track-icon
{
	position:absolute;
	top: 230px;
	left:50%;
	margin-left: -30px;
	width:60px;
	height:60px;
	background-size:30px 30px;
	background-position:center;
	background-repeat: no-repeat;
	background-color:#FFF;
	border-radius:60px;
}
.play-track-video-btn
{
	position: absolute;
	bottom: 45px;
	left:50%;
	margin-left: -35px;
	color: #FFF!important;
}
.indicator-popover
{
	left: 90px;
	top: 0px;
}
.main-fest-logo-cont
{
	width: 100px;
    height: 100px;
    padding: 20px;
    background-color: rgb(237, 239, 242);
    margin-bottom: 20px;
}
.no-no-no
{
	position:relative;
}
.no-no-no::before
{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #de6f00;
	z-index: 1;
	clip-path: polygon(3% 0, 0 4%, 48% 50%, 0 95%, 4% 100%, 51% 54%, 97% 100%, 100% 96%, 55% 50%, 100% 4%, 96% 0, 51% 47%);
}
.member-descr-text
{
	padding: 0 0 10px 0;
}
.member-descr-author
{
	padding: 0 40px 10px 20px;
	font-weight: bold;
	text-align:right;
}
.critery-descr
{
	margin-top:9px;
}
.main-fest-contacts
{
	display: flex;
	padding: 2px 10px;
	margin-bottom: auto;
	font-size: 11px;
	color: #EEE;
	justify-content: flex-end;
	position: relative;
	z-index: 1;
	&::before
	{
		content: "";
		position: absolute;
		top: 0px;
		left: -10px;
		width: calc(100% + 20px);
		height: 40px;
		background-color: #293742cc;
		z-index: -1;
	}
	@media (max-width: 760px) {
		.contact-slide-mobile {
			min-height: 25px;
			justify-content:center;
			display:flex;
			align-items: center;
		}
		.pe-prev-button-container,
		.pe-next-button-container {
			position: absolute;
			top: 3px;
			left:0;
			z-index: 100;
			cursor:pointer;
			width: 20px;
			min-height: 20px;
			font-size: 20px;
			background-color: #00000055;
			color: #FFF;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 2px;
		}
		.pe-next-button-container { 
			right:0; 
			left: auto;
		}
	}
	
} 
.pe-fest-clear-filter-cont
{
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 5px;
	height: 90px;
	transition: width 200ms ease-in-out;
}
.pe-fest-clear-filter-cont.open
{
	width: 28px;
}
.pe-fest-clear-filter-close-button
{
	transform: translateX( 30px);
	transition-delay: 400px;
	transition: all 600ms ease-in-out;
}
.open >.pe-fest-clear-filter-close-button
{
	transform: translateX(0);
}
.display-name-label
{
	width: 80px;
    height: 16px;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.stroke-a-dark a, 
.a-dark
{
	color:#505f7a!important;
	transition: all 200ms ease-in-out;
}
.stroke-a-dark:hover a, 
.a-dark:hover
{
	color:#100!important;
}
.corner-3
{
	transform: rotate(45deg) translateY(60%) translateX( 23%)!important;
}
.my-avatar-cont
{
	min-height: 40px;
    height: 40px;
    min-width: 40px;
    width: 40px;
    overflow: hidden;
	background-position:center; 
	background-size:cover;
}
.project-order-label {
	width: 22px;
	height: 22px;
	background-color: #6c757d;
	color: #EEE;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	margin-right: 12px;
}
.my-avatar-cont > img.avatar
{
	margin-top:0;
	margin-left:0;
}
.card.is_buisness{
	position: relative;
	&::before {
		content: "";
		width: 100%;
		height: 100%;
		background-color: #0944a23e;

		position: absolute;
	}
	.business-case-label {
		display: flex;
		span {
			transition: 300ms all ease-out;
			margin-right: auto;
			margin-top: 4px;
			background-color: #31496fd1;
			color: #ffffffd1;
			font-size: 7px;
			// text-transform: uppercase; 
			letter-spacing: 2px;
			padding: 1px 12px;
			border-radius: 0 4px 4px 0;
		}
	}
	&:hover .business-case-label span { 
		background-color: #862c45;
	}
}

img.track-miniature, .ganre-miniature
{
	height:30px;
	width:auto;
	margin-right:10px;
	margin-left:10px;
}
.ganre-label
{
	width: 60px;
	height: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: .25em;
	margin: 2px;
	div {
		width: 60px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	&.small{
		height: 22px;
		width: auto;
		display: inline-block;
		div {
			width: auto;
		}
	}
}
.pe-fest-help-menu
{
	width:800px;
	height:450px;
	max-width: 100%;
	display:flex;
	justify-content:center;
	align-items: center;
	flex-direction:column;
	padding:15px;
}
.pe-fest-member-map
{
	width: 100%;
	max-width: 600px;
	height: 370px;
	background-color:#00000001;
	padding:10px;
	overflow-x: hidden;
}
.pe-fest-member-map > svg > path
{
	opacity: .7; 
	stroke-width: 1;
	stroke: #00000033;
	transition: all 300ms ease-out;
}
.pe-fest-member-map > svg > path:hover
{
	opacity: 1;
}
.pe-fest-member-map-label
{
	position:absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index:1;
	top:50%;
	left:50%;
	width:300px;
	height:100px;
	background-color: #3f5060;
	color:#FFF;
	margin-left:-150px;
	margin-top:-50px;
}
.pe-fest-table-statistics th,
.pe-fest-table-statistics td
{
	min-height: 55px;
    padding: 0.75rem!important;
	max-width:100vw;
	vertical-align: top!important;
}
.h-40px
{
	height:40px;
}
.typa-correct
{
	width:100%;
	height:100%;
}
.typa-correct input
{
	font-size: 1.25rem!important; 
	text-align: left!important;
	padding: 0;
}
.track-moder-title-cell > .cell-hover
{
	opacity:0;
}
.track-moder-title-cell:hover > .cell-hover
{
	opacity:1;
}
.pe-insert-critery-container { 
	gap: 10px;
}
@media (max-width: 540px)
{
	.indicator
	{ 
    	min-width: 60px!important;
		height: 75px !important;
		max-width: 80px;
	}
	.display-name-label
	{
		width: 50px;
	}

}
@media (max-width: 760px)
{
	
	.pe-fest-member-map
	{ 
		overflow-x: auto;
		overflow-y: hidden;
	}
	.diary_post
	{
		padding: 5px 25px;
	}
	.bl-20
	{
		border-left-color:transparent!important;
		border-left-width:0;
	}
	.critery_cell.first 
	{
		border-bottom-color:transparent!important;
	}
	.milestones-cont
	{
		padding-left:10px;
		padding-right:10px;
		border-bottom:none;
		flex-wrap:wrap;
	}
	.milestone-button
	{
		margin: 2px;
		border-radius: 4px;
		border: none;
		min-width: 100%;
	}
	.milestone-button.active
	{
		background-color: #ffffff;
		padding: 12px 37px 19px;
	}
	.main-fest-jumbotron-container
	{
		height: 170px;
		min-height: 170px;  
	}
	.main-fest-jumbotron
	{
		align-items: center;
	} 
	.main-fest-title,
	.main-fest-description
	{
		text-align: center;
		margin-left: 20px;
		margin-right: 20px;
		font-size: 30px;
		line-height: 1.1;
		margin-bottom:1px;
	}
	.main-fest-description
	{
		font-size: 16px;
		margin-bottom: 10px;
	}
	.main-fest-title::before,
	.main-fest-description::before { 
		height: 133%;
		top: 0px;
	}
	.main-fest-logo-cont
	{
		margin-left: auto;
		margin-right: auto;
	}
	.mine-label-stroke,
	.my-track-label-stroke, 
	.mine-honeycombs-label-stroke
	{
		text-align: center;
		justify-content: center;
	}
}
.pe-add-festival-container {
	width: 100%;
	max-width: 100%;
	min-width: 100%;
	flex-grow: 100;
	display: flex;
	 
	.pe-add-festival-menu-container {
		width: 240px;
		min-width: 240px; 
		border-right: 1px solid var(--force-mini-contast-color); 
		display:flex;
		flex-direction: column;
		align-items: center;
	}
}
.pe-track-edit-card-container,
.pe-user-edit-card-container,
.pe-category-edit-card-container,
.pe-milestone-edit-card-container,
.pe-critery-edit-card-container,
.pe-project-edit-card-container {
	width: 100%;
	height: 200px;
	min-height: 200px;
	display: flex; 
	background-color: var(--force-mini-contast-color); 
	border-radius: 4px;
	border: 0px solid transparent;
	overflow:hidden;
	&.no-evailabled {
		background-color: #e6cccc33;
		border-color:#a67f7f88;
	}
	.thumb { 
		background-color: var(--force-mini-contast-color);
		height: 100%;
		min-height: 200px;
		min-width: 200px;
		width: 200px;
		background-size:cover;
		background-position: center;
		background-repeat: no-repeat;
		@media (max-width: 576px) {
			min-width: 50px;
			width: 50px;
		}
	}
	.title { 
		display: flex;
		align-items: center;
		font-size: 1.3em;
		font-family: Open sans, sans-serif;
		font-weight: 100; 
		border-bottom: 1px dotted var(--force-mini-contast-color);
		color:var(--force-contast-color);
		.track-edit-title {
			width: 100%!important;
		}
	}
	.content { 
		border-bottom: 1px dotted var(--force-mini-contast-color);
		text-overflow: ellipsis;
		overflow:hidden;
		min-height: 130px;
		.track-content-edit.bp5-editable-text-editing textarea { 
			min-height: 130px;
			padding: 12px 20px;
		}
		.track-content-edit.bp5-editable-text textarea { 
			min-height: 128px;  
			padding: 12px 20px;
		}
	}
	.footer {
		display: flex;
		justify-content: flex-end;
		padding: 0px 20px;
	}
}
.pe-milestone-edit-card-container,
.pe-critery-edit-card-container,
.pe-category-edit-card-container {
	height: 95px;
	max-height: 95px;
	min-height: 95px;
	.content {
		min-height: 25px;
	}
	.footer {
		justify-content: space-between;
		padding:0;
		position:relative;
		.pe-color-picker-example {
			margin:0;
			border:0;
			width: 200px;
			@media (max-width: 576px) {
				min-width: 100px;
				width: 100px;
			}
		}
		.pe-fest-resource-icon {
			width: 14px;
			height: 14px;
			margin-right: 7px;
		}
	}
}
.single-project-container {
	position:relative;
	
}
.thumb .media_button {
	box-shadow: none;
}
  
@media (max-width: 600px) {
	.milestone-attach-cont .milestone-attach-change-btn
	{
		opacity: 1;
		background-color: transparent;
	}
	h2 {
	  font-size: 1.8rem;
	}
	
	.reveal-text-- {
		visibility: hidden;
	}
	
	.line {
		position: relative;
		overflow: hidden;
		display: flex;
	}
	
	section {
		background: #c9c7b4;
		display: grid;
		place-items: center;
		min-height: 100vh;
		width: 100%;
		padding: 10vw;
	}
	
	section:nth-child(2) {
		background: #efeae7;
	}
	
	section:nth-child(3) {
		background: #d3a488;
	}
	
	* {
		box-sizing: border-box;
	}
	
	.credit {
		position: fixed;
		top: 20px;
		right: 20px;
		font-family: Termina, sans-serif;
		font-weight: 800;
		font-size: 0.7rem;
	}
	
	.credit a {
		color: #1a292c;
		text-transform: lowercase;
	}

}
@media (max-width: 576px)
{
	.status-descr {
		width: 100vw;
		text-align: center; 
		display: none;
	}
	.table td, .table th
	{
		display: block;
	}
	.table td.half, .table th.half
	{
		display: block;
		width:50%;
		float:left;
	}
	.uabtn
	{
		height: 50px;
		flex-direction:row;
		justify-content: left;
	}
	
	.uabtn > span,
	.uabtn > div
	{
		margin:0 10px;
		text-align:left;
	}
	.uabtn-cont
	{
		min-height:auto;
	}
	.table th, .table td,
	.table thead th
	{
		border-top-color:transparent!important;
		border-bottom-color:transparent!important;
	}
	.table tr
	{
		border-top: 1px solid #dee2e6;
	}
	.display-4 
	{
		font-size: 1.5rem!important;
	}
}
.pe-project-mobile-form-container {
	height: var(--main-height);
	width: 100%;
	display:flex;
	flex-direction: column;
	position:relative;
	.swiper {
		height:  var(--main-height);
		width: 100%;
		.screen {
			padding: 10px;
			padding-top: 50px;
			padding-bottom: 50px;
			height: 100%;
			
		} 
	}
	.settings-screen {
		background-color: $settings-color;
	}
	.rating-screen {
		background-color: $rating-color;
	}
	.manage-screen {
		background-color: $manage-color;
	}
	.descriptions-screen {
		background-color: $descriptions-color;
	}
	.team-screen {
		background-color: $team-color;
	}
	.diary-screen {
		background-color: $diary-color;
	}
	.pe-fest-project-title-mobile {
		position: absolute;
		z-index: 1;
		top:10px;
		left: 10px;
		width: calc(100% - 20px);
		text-align:center;
		font-size: 1.3rem;
		font-weight: 100;
		line-height: 1.0;
	}
	.pe-project-mobile-navy-container {
		position:absolute;
		bottom: -0px;
		width: 100%;
		display:flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		z-index:3;
		
	}
}

.navi-btn {
	height: 44px;
	cursor:pointer;
	width: 44px;
	border-radius: 44px;
	margin: 2px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow: hidden;
	transition: all 300ms ease-out;
	background-color: #b3b4c3;
	.icon {
		background-position: center;
		background-size: 18px;
		background-repeat: no-repeat; 
		height: 44px;
		width: 44px;
	}
	span.navi-title {
		display: none;
		color: #FFF;
	}
	&.active { 
		width: 188px; 
		background-color: #556475;  
		.icon { 
			background-size: 30px;
			filter: invert(100%);
		}
		span.navi-title {
			display:inline-block;
			width: 92px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			padding-right: 7px;
			flex-grow: 100;
			font-size: 12px;
		}
		@media (max-width: 576px) {
			width: 56px;
			border-radius: 4px;
			span.navi-title {
				display: none;
			}
		}
	}  
}
.pe-fest-resource-icon {
	background-position: center;
	background-size: 18px;
	background-repeat: no-repeat; 
	height: 30px;
	width: 30px;
}

.project-mobile-quotes-container {
	& > .swiper {
		min-height: 190px!important;
		height: auto!important;
		max-width: 90vw!important;
		.quote-text {
			min-height: 100px;
			max-height: calc(100vh - 486px);
			padding: 10px;
		}
		.swiper-pagination {
			transform: translateY(10px);
			.swiper-pagination-bullet {
				width: 14px;
				height: 14px;
			}
			.swiper-pagination-bullet-active {
				background-color: #e4d9d9;
			}
		}
		
	}
}
.pe-festival-messages-widget-container {
	min-width: 25px;
	height:25px;
	background-color: rgba(0, 0, 0, 0.69);
	color:#EEE!important;
	font-size: 10px;
	border-radius: 40px;
	display:flex;
	justify-content: center;
	align-items:center; 
	transform: translateX(5px) translateY(-20px);
    z-index: 10;
    position: absolute;
}

.pe-stew-tab-container {
	min-height: 600px;
	display: flex;
    flex-direction: column;
}

.cabinet-carousel {
	.pe-prev-button-container {
		position: absolute;
		color:#EEE;
		bottom:0;
		left:0;
		padding: 10px 18px;
		background-color: #1c2e52;
		font-size: 25px;
		cursor: pointer;
		z-index: 1000;
		i {
			opacity:.75;
			&:hover {
				opacity:1;
			}
		}
	}
	.pe-next-button-container {
		position: absolute;
		color:#EEE;
		bottom:0;
		right:0;
		padding: 10px 18px;
		background-color: #1c2e52;
		font-size: 25px;
		cursor: pointer;
		z-index: 1000;
		i {
			opacity:.75;
			&:hover {
				opacity:1;
			}
		}
	}
	.swiper-pagination {
		display: inline-flex;
    	margin-left: 100px;
		padding: 10px;
		.swiper-pagination-bullet {
			width: 16px;
			height: 16px;
			background-color: var(--force-contast-color);
		}
		@media (max-width: 540px) {
			margin-left: 50px; 
			.swiper-pagination-bullet {
				width:  10px;
				height: 10px;
			}
		}
	}
}
.event-carousel-phase-form-container {
	padding: 20px 20px 40px 20px;
	width: calc(100% - 50px);
	@media (max-width: 540px) {
		padding: 0px;
		width: 100%;
		padding-bottom: 40px;
	}
}
.pe-track-vk-link {
	position:absolute;
	margin:3px;
	bottom:3px;
	top:auto;
	left:3px;
	right: auto;
}
.pe-track-video {
	position:absolute;
	margin:3px;
	bottom:3px;
	top:auto;
	right:3px;
	left: auto;
}
.available-for-label-container {
	position:relative;
	height: 16px; 
	&:before {
		content: "";
		position:absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-250px) translateY(-50px);
		width: 500px;
		height: 100px;
		background: radial-gradient(ellipse, rgba(2,0,36,.33) 0%, rgba(0,0,0,0) 50%);
		z-index:0;
	}
	.label {
		position:relative;
		z-index:1;
		color: #EEEEEE;
		letter-spacing: 2px;
	}
	display: flex;
	justify-content: center;
}
.critery-descr p,
.pe-critery-single-descriptions-container p {
	margin-bottom:2px;
}