blockquote {
	margin:0!important;
	padding:0!important;
}
.lead { 
    font-family: Open Sans;
}
.pe-light {
	background-color: #FFF!important;
}
button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
    border: none;
}
.bp5-collapse .bp5-collapse-body {
	min-width:100%;
}
.bg-white {
	background-color: #FFFFFF;
}
.bg-pumpkit-dark {
	background-color: #b36f2a !important;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.sticky-top {
	z-index: 98!important;
}
.ml-20 {
	margin-left: -20px!important;
}
.mr-20 {
	margin-right: -20px!important;
}
.ml-15 {
	margin-left: -15px!important;
}
.mr-15 {
	margin-right: -15px!important;
} 
.row-right-plus-15 {
	width: calc(100% + 15px)!important;
}
.col-height-full {
	height: calc(100vh - 40px)!important;
}
.col-* img {
	max-width: 100%;
}
@media screen and (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}
.bg-md-dark {
	@media (max-width: 768px) {
		background-color:#181e24;
	}
}
.rounded-pill-0 {
	border-top-left-radius: 25px!important;
}
.rounded-pill-1 {
	border-top-right-radius: 25px!important;
}
.rounded-pill-2 {
	border-bottom-right-radius: 25px!important;
}
.rounded-pill-3 {
	border-bottom-left-radius: 25px!important;
}
.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}
select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}
.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.m-auto {
    margin: auto!important;
}
.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}
.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}
.mr-1 {
	margin-right: 0.25rem !important;
}
.mr-2 {
	margin-right: 0.5rem !important;
}
.mr-3 {
	margin-right: 1rem !important;
}
.mr-4 {
	margin-right: 1.5rem !important;
}
.mr-5 {
	margin-right: 3rem !important;
}
.mr-6 {
	margin-right: 4rem!important;
}
.mr-7 {
	margin-right: 5rem!important;
}
.mrl-1 {
	margin-left: 0.25rem !important;
}
.ml-2 {
	margin-left: 0.5rem !important;
}
.ml-3 {
	margin-left: 1rem !important;
}
.ml-4 {
	margin-left: 1.5rem !important;
}
.ml-5 {
	margin-left: 3rem !important;
}
.ml-6 {
	margin-left: 4rem!important;
}
.ml-7 {
	margin-left: 5rem!important;
} 
.mrt-1 {
	margin-top: 0.25rem !important;
}
.mt-2 {
	margin-top: 0.5rem !important;
}
.mt-3 {
	margin-top: 1rem !important;
}
.mt-4 {
	margin-top: 1.5rem !important;
}
.mt-5 {
	margin-bottom: 3rem !important;
}
.mt-6 {
	margin-bottom: 4rem!important;
}
.mt-7 {
	margin-bottom: 5rem!important;
}
.mrb-1 {
	margin-bottom: 0.25rem !important;
}
.mb-2 {
	margin-bottom: 0.5rem !important;
}
.mb-3 {
	margin-bottom: 1rem !important;
}
.mb-4 {
	margin-bottom: 1.5rem !important;
}
.mb-5 {
	margin-bottom: 3rem !important;
}
.mb-6 {
	margin-bottom: 4rem!important;
}
.mb-7 {
	margin-bottom: 5rem!important;
}
.m-0 {
	margin: 0rem!important;
}
.m-1 {
	margin: 0.25rem!important;
}
.m-2 {
	margin: 0.5rem!important;
}
.m-3 {
	margin: 1rem!important;
}
.m-4 {
	margin: 1.5rem !important;
}
.m-5 {
	margin: 3rem !important;
}
.m-6 {
	margin: 4rem!important;
}
.m-7 {
	margin: 5rem!important;
}

.pr-1 {
	padding-right: 0.25rem !important;
}
.pr-2 {
	padding-right: 0.5rem !important;
}
.pr-3 {
	padding-right: 1rem !important;
}
.pr-4 {
	padding-right: 1.5rem !important;
}
.pr-5 {
	padding-right: 3rem !important;
}
.pr-6 {
	padding-right: 4rem!important;
}
.pr-7 {
	padding-right: 5rem!important;
}
.prl-1 {
	padding-left: 0.25rem !important;
}
.pl-2 {
	padding-left: 0.5rem !important;
}
.pl-3 {
	padding-left: 1rem !important;
}
.pl-4 {
	padding-left: 1.5rem !important;
}
.pl-5 {
	padding-left: 3rem !important;
}
.pl-6 {
	padding-left: 4rem!important;
}
.pl-7 {
	padding-left: 5rem!important;
} 
.prt-1 {
	padding-top: 0.25rem !important;
}
.pt-2 {
	padding-top: 0.5rem !important;
}
.pt-3 {
	padding-top: 1rem !important;
}
.pt-4 {
	padding-top: 1.5rem !important;
}
.pt-5 {
	padding-bottom: 3rem !important;
}
.pt-6 {
	padding-bottom: 4rem!important;
}
.pt-7 {
	padding-bottom: 5rem!important;
}
.prb-1 {
	padding-bottom: 0.25rem !important;
}
.pb-2 {
	padding-bottom: 0.5rem !important;
}
.pb-3 {
	padding-bottom: 1rem !important;
}
.pb-4 {
	padding-bottom: 1.5rem !important;
}
.pb-5 {
	padding-bottom: 3rem !important;
}
.pb-6 {
	padding-bottom: 4rem!important;
}
.pb-7 {
	padding-bottom: 5rem!important;
}
.py-2 {
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
} 
.py-3 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
} 
.pb-4 {
    padding-bottom: 1.5rem !important;
}

.anti-row {
	margin-left: -15px;
	margin-right: -15px;
}
.anti-row-left {
	margin-left: -15px;
}
.anti-row-right {
	margin-right: -15px;
}
.anti-double-row {
	margin-left: -30px;
	margin-right: -30px;
}
.anti-double-row-left {
	margin-left: -30px;
}
.anti-double-row-right {
	margin-right: -30px;
}
.px-5 {
    padding-right: 3rem!important;
    padding-left: 3rem!important;
}
.max-w-100 {
	max-width: 100%;
}
.bp5-dialog .dialog-close-btn {
	right: -40;
	left: "auto";
}

.btn-primary 
{
    color: #fff;
    background-color: #dcad37!important;
    border-color: #c79d32!important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus
{
    background-color: #bb932e!important;
    border-color: #846821!important;
}
.bg-secondary
{
	background-color: #6c8297!important;
}
.btn-secondary
{
	background-color: #6c8297!important;
	border-color: #6c8297!important;
}
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary:focus
{
	background-color: #566676!important;
	border-color: #566676!important;
}
.bg-dark-secondary
{
	background-color: #374f65!important;
}

.bg-pumpkit
{
	background-color: #e29649 !important;
} 
.bg-pumpkit-dark
{
	background-color: #b2773b !important;
} 
.bg-secondary-dark
{
	background-color: #33485bdf !important;
}
.bg-secondary-super-dark
{
	background-color: #101214df !important;
}
.bg-secondary-cloudy
{
	background-color: #171f2856 !important;
}
.bg-secondary-super-cloudy
{
	background-color: #2e40521e !important;
}
.bg-secondary-light
{
	background-color: #6c757d66 !important;
}
.bg-secondary-super-light
{
	background-color: #6c757d11 !important;
}
.bg-warning-light
{
	background-color: #fdd9b4a1 !important;
}

.border2 {
	border: 1px solid #00000020;
}
.bg-dark-light
{
	background-color: #0d0f120a !important;
}
.bg-danger-dark {
	background-color: #7c131d !important;
}
.bg-danger-super-dark {
	background-color: #510a11 !important;
}
.bg-blur {
	backdrop-filter: blur(5px);
}
.border-transparent {
	border-color: transparent!important;
}
.border-bottom-1 {
	border-bottom: 1px solid #6c757d;
}
.border-bottom-2 {
	border-bottom: 1px solid #e6eaee15;
}
.border-top-2 {
	border-top: 1px solid  #6c757d;
}
.border-secondary-medium {
	border-color: #6c757d36 !important;
}
.border-secondary-light {
	border-color: #6c757d11 !important;
}
.border-secondary-super-light {
	border-color: #e9f0f706 !important;
}
.border-light-light {
	border-color: #ffffff31 !important;
}
.border-light-super-light {
	border-color: #ffffff1a !important;
}
.border-dotted {
	border-style: dotted;
}

.bp5-portal.overflow-hidden > .bp5-overlay {
	overflow:hidden;
}
.text-black {
	color:#111!important;
}
.w-md-50 {
	width: 50%;
	@media (max-width: 768px){
		width: 100%;
	}
}
.w-md-33 {
	width: 33%;
	@media (max-width: 768px){
		width: 100%;
	}
}
.w-md-66 {
	width: 66%;
	@media (max-width: 768px){
		width: 100%;
	}
}
.big {
	font-size:1.25em;
}
.light-font {
	font-weight: 100!important;
}
 
@media (min-width: 1400px) {
	.col-xxl-12 {  
		width: 100%!important;
		flex: 0 0 100%!important; 
	}
	.col-xxl-11 {  
		width: 91.666%!important;
		flex: 0 0 91.666%!important; 
	}
	.col-xxl-10 {  
		width: 83.333%!important;
		flex: 0 0 83.333%!important; 
	}
	.col-xxl-9 {  
		width: 75%!important;
		flex: 0 0 75%!important; 
	}
	.col-xxl-8 {  
		width: 66.666%!important;
		flex: 0 0 66.666%!important; 
	}
	.col-xxl-6 {  
		width: 50%!important;
		flex: 0 0 50%!important; 
	}
	.col-xxl-5 {  
		width: 41.666%!important;
		flex: 0 0 41.666%!important; 
	}
	.col-xxl-4 {  
		width: 33.333%!important;
		flex: 0 0 33.333%!important; 
	}
	.col-xxl-3 {  
		width: 25%!important;
		flex: 0 0 25%!important; 
	}
	.col-xxl-2 {  
		width: 16.666%!important;
		flex: 0 0 16.666%!important; 
	}
	.col-xxl-1 {  
		width: 8.333%!important;
		flex: 0 0 8.333%!important; 
	}
}
@media (min-width: 1700px) {
	.col-slg-12 {  
		width: 100%!important;
		flex: 0 0 100%!important; 
	}
	.col-slg-11 {  
		width: 91.666%!important;
		flex: 0 0 91.666%!important; 
	}
	.col-slg-10 {  
		width: 83.333%!important;
		flex: 0 0 83.333%!important; 
	}
	.col-slg-9 {  
		width: 75%!important;
		flex: 0 0 75%!important; 
	}
	.col-slg-8 {  
		width: 66.666%!important;
		flex: 0 0 66.666%!important; 
	}
	.col-slg-6 {  
		width: 50%!important;
		flex: 0 0 50%!important; 
	}
	.col-slg-5 {  
		width: 41.666%!important;
		flex: 0 0 41.666%!important; 
	}
	.col-slg-4 {  
		width: 33.333%!important;
		flex: 0 0 33.333%!important; 
	}
	.col-slg-3 {  
		width: 25%!important;
		flex: 0 0 25%!important; 
	}
	.col-slg-2 {  
		width: 16.666%!important;
		flex: 0 0 16.666%!important; 
	}
	.col-slg-1 {  
		width: 8.333%!important;
		flex: 0 0 8.333%!important; 
	}
}
@media (min-width: 2000px) {
	.col-glg-12 {  
		width: 100%!important;
		flex: 0 0 100%!important; 
	}
	.col-glg-11 {  
		width: 91.666%!important;
		flex: 0 0 91.666%!important; 
	}
	.col-glg-10 {  
		width: 83.333%!important;
		flex: 0 0 83.333%!important; 
	}
	.col-glg-9 {  
		width: 75%!important;
		flex: 0 0 75%!important; 
	}
	.col-glg-8 {  
		width: 66.666%!important;
		flex: 0 0 66.666%!important; 
	}
	.col-glg-6 {  
		width: 50%!important;
		flex: 0 0 50%!important; 
	}
	.col-glg-5 {  
		width: 41.666%!important;
		flex: 0 0 41.666%!important; 
	}
	.col-glg-4 {  
		width: 33.333%!important;
		flex: 0 0 33.333%!important; 
	}
	.col-glg-3 {  
		width: 25%!important;
		flex: 0 0 25%!important; 
	}
	.col-glg-2 {  
		width: 16.666%!important;
		flex: 0 0 16.666%!important; 
	}
	.col-glg-1 {  
		width: 8.333%!important;
		flex: 0 0 8.333%!important; 
	}
}

@media (max-width: 768px){
	.bp5-dialog {
		width: 100vw!important;
		min-width: 100vw!important;
		height: 100vh!important;
		min-height: 100vh!important;
		top: 0;
		margin: 0!important;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.bp5-dialog .dialog-close-btn {
		left: 50%;
		right: auto;
		top: 90px;
		transform: translateX(-25px);
	}
	.bp5-button .bp5-button-text {
		text-align:center;
	}
	.dialog-close-btn svg {
		width:50px;
		height:50px;
	}
}
.small {
	p {
		margin-bottom: 5px;
		margin-top: 0;
	}
}