.app__main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.app__main-container > *:not(:last-child) {
  margin-bottom: 50px;
}

.item-0 {
  background-color: rgb(87, 99, 106);
}
.item-1 {
  background-color: cornflowerblue;
}
.item-2 {
  background-color: coral;
}
.item-3 {
  background-color: darkseagreen;
}
.item-4 {
  background-color: rgb(157, 87, 128);
}
.item-5 {
  background-color: rgb(195, 170, 109);
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  height: 100%;
  width: 100%;
  position: relative;
}
