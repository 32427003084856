$headerHeight: 90px;
$headerHeightSymbol: $headerHeight  - 30px;
$headerLoginnedMenuTop: $headerHeight - 8px;
.text-force-contrast {
	color:#111!important;
}
.user-select-none {
	user-select: none;
}
#root {
    // Scss variables which we gonna assign using
    // useState in layout-app/index.tsx
    
    $header-height: $headerHeight;
    $header-height-symbol: $headerHeightSymbol;
    $header-loginned-menu-top: $headerLoginnedMenuTop;
    $iconoc-width: 90px;
    $header-menu-size: 50px;
}
.pe-admin-widget-btn {
	width: 100%;
}
.pe-admin-widget-btn.active {
	background-color: #00000033;
}

.gallery-dialog-thumbnail {
    max-height:90vh;
    width: 100%;
    max-width:800px;
    transition: 400ms all ease-out;
    &.full-size {
        max-height: 100vh;
        max-width:100vw;
        height: 100vh;
    }
}
.gallery-thumbnail-container {
	position: absolute;
	bottom:0;
	left:0;
	width: 100%;
	z-index: 1;
	background-color: #0000008a;
	padding: 20px;
}
.gallery-icon {
    margin:5px;
    cursor:pointer;
    opacity:.75;
    border:1px solid transparent;
    transition: 200ms opacity ease-out;
    &.active {
        border-color:#FFF;
    }
    &:hover {
        opacity: 1;
    }
    img {
        width:80px;
    }
}
.header-menu-icon {
	color: #FFF;
}
.layout-header
{
	display: flex;
    height: var(--header-height);
    --background-color: #30404D;
    align-items: center;
    border-bottom: 1px solid #1c262d;
    position: relative;
	
}
.layout-header.fixed
{
	position:fixed;
    width: 100%;
    z-index: 1200;
    background: #FFF;
    left: 0;
    top: 0;
    max-height: var(--header-height);
}
.layout-header-title
{
	font-family: 'Yanone Kaffeesatz', sans-serif;
	font-size: 1.7rem; 
	margin-left: 10px;
	align-self: center;
	text-overflow: ellipsis;
	overflow: hidden;
    white-space: nowrap;
}
.layout-header-description
{
	font-family: Open Sans, sans-serif;
	font-size: .8rem;
}
.landing-archor-hamburger-menu-cont
{
	position: absolute;
    top: 60px;
    background-color: #999;
    z-index: 10;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
	overflow:hidden;
	-webkit-transition: all 300ms ease-out;
	-moz-transition: all 300ms ease-out;
	-ms-transition: all 300ms ease-out;
	-o-transition: all 300ms ease-out;
	transition: all 300ms ease-out;
}

.layout-header-icon
{
	width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
	margin: 10px;
}
.layout-header-icon > svg 
{
	width:17px;
	height:17px;
	fill: #fde5a8;
	fill-opacity:0.8;
}
.layout-header-icon:hover > svg 
{
	fill-opacity:1;
}
.icon-unlogined,
.icon-logined
{
	display:flex;	
	align-items: center; 
	color: #211905;
	padding-right:20px;
    position: relative;
    height: 100%;
    min-width: 220px;
	flex-wrap: wrap;
	text-decoration:none!important;
}

.header-menu-element
{
	display:flex;
}

.header-menu-element.iconic {
	flex-direction:column;
	font-size: 11px;
    text-align: center;
    align-items: center;
    justify-content: center;
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
	transition: width 700ms ease-out, max-width 700ms ease-out;
	&.opened {
		width: var(--iconoc-width);
		max-width: var(--iconoc-width);
	}
	&.closed {
		width: 0;
		max-width: 0;
	}
}
.header-menu-icon
{
	display: inline-block;
    margin-right: 7px;
	opacity: .9;
}
.header-menu-icon > svg
{
	height: 22px;
	width: 22px;
}

.iconic .header-menu-icon {
	font-size: var(--header-height-icon);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: var(--header-height-symbol) ;
    align-items: center;
    display: flex;
	transition: all 300ms ease-out;
	@media screen and (max-width: 560px) {
		height: 40px;
	}
}
.iconic .header-menu-icon img {
	width: var(--header-menu-size);
	height: var(--header-menu-size);
}
.iconic > svg {
	font-size:40px;
}
.iconic .header-menu-label {
	width: var(--iconoc-width);
    max-width: var(--iconoc-width);
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.with-header .layout-state
{
	margin-top:75px;
	height: calc(100vh - 80px);
}
.layout-state-head
{
	display:flex;
	align-items: center;
    margin-bottom: 20px;
	position: relative; 
	padding:20px;
}

.layout-state-logo
{
	width: 40px;
    height: var(--header-height);
	color: #232323;
    margin-right: 10px;
    background-size: cover;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.layout-state-logo > svg 
{
	width:28px;
	height:28px;
	fill: #232323;
}

.layout-state-title
{
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 200;
    font-size: 2.6rem;
        color: #232323;
    letter-spacing: 1px;
}
.layout-state-description
{
	font-size: 1.1rem;
    margin: 30px 0;
    color: #4d5e69;
    font-style: italic;
}

.layout-state-heaader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    flex-grow: 100;
	overflow: visible;
	z-index: 2;
}
/*TODO здесь нужно все переписать*/
.layout-header-link {
	padding-right: 10px;
	padding-left: 10px;
	color: #FFFFFF !important;
	opacity: 0.8;
	border-bottom: 4px solid transparent;
	-webkit-border-radius: 0;
	border-radius: 0;
	text-decoration: none !important;
}
.user-name {
    text-align: center;
}

/*TODO здесь нужно все переписать*/
.layout-header-help > span
{
	display:inline-block;
	color: #abccb6;

}

/*TODO здесь закомментировано, так как просили текст у link*/
/*.layout-header-link > span,*/
.layout-header-help > span 
{
	display:none;
}

.podmenu
{
	align-self: flex-start;
	padding: 15px 25px;
	align-items: center;
	display: flex;
	border-bottom: 1px solid #FFFFFF20;
	border-left: 4px solid transparent;
	width: 100%;
	//color: #abccb6;
	cursor:pointer;
	opacity:.75;
}
.podmenu:hover {
	opacity:1;
}

.podmenu.active
{
	border-bottom: 1px solid #FFFFFF20;
	border-left: 4px solid #FFC940;
	color: #FFC940;
	text-decoration: none!important;
	background-color: #3e414b!important;
}

.layout-header-inner
{
	display:flex;
	width:100%;
}


.icon-logined.active
{
	background-color: #202B33;
	color: #bba56a;
}
.icon-unlogined .icon,
.icon-logined .icon
{
	// background-image:url(../img/user-logo.svg);
	width:39px;
	height:39px;
	margin:0 15px;
	background-size:cover;
}
.icon-logined .icon
{
	border-radius:50%;
} 

.icon-unlogined.iconic {
	font-size:11px;
	flex-direction: column;
}
.icon-unlogined.iconic i {
	font-size:40px;
	opacity: 0.5;
	margin-bottom:4px;

}

.podmenu-cont {
	min-width: 110px;
	display:flex;
	flex-direction: column;
	padding:0;
}
.btn-podmenu {
	padding: 12px 25px;
	position:relative;
}
.btn-podmenu:hover {
	color: #FFF!important;
	background-color: #000000CC;
}
.icon-logined.iconic,
.icon-unlogined.iconic 
{
	flex-direction: column;
	justify-content: center;
	height: var(--header-height);
	min-width: var(--iconoc-width);
	margin-top: 0px;
}
.iconic .user-ava
{
	height: 40px;
    width: 40px;
    min-width: 40px;
    margin-bottom: 4px;
    margin-left: auto!important;
    margin-right: 10px!important; 
}
.iconic .user-name
{
	font-size: 11px;
	white-space: nowrap;
	width: var(--iconoc-width);
	overflow: hidden;
	text-overflow: ellipsis;
}

.logined-menu
{
	height:0;
	position: absolute;
    width: 280px;
    overflow: hidden;
    background-color:#293742;
    z-index: 300;
    top: var(--header-loginned-menu-top);
	-webkit-transition: height 250ms ease-out;
	-moz-transition: height 250ms ease-out;
	-ms-transition: height 250ms ease-out;
	-o-transition: height 250ms ease-out;
	transition: height 250ms ease-out;
}
.logined-menu > ul
{
	padding:0!important;
	display:flex;
	flex-direction:column;
	align-self:flex-end;
	margin-top:0;	
	-webkit-transition: margin-top 350ms ease-out;
	-moz-transition: margin-top 350ms ease-out;
	-ms-transition: margin-top 350ms ease-out;
	-o-transition: margin-top 350ms ease-out;
	transition: margin-top 350ms ease-out;
}
.icon-logined.active .logined-menu > ul
{
	margin-top:0px;
}
.unsign {
	background-color: #1c2937; 
	color:#EEE;
	border: 1px solid #7d7d7d;
}
ul.unsign {
	list-style-type: none;
	margin-left: 0;
	padding-left:0; 
}
.logined-menu > ul  > .lmenu
{
	cursor:pointer;
	position:relative;
}
.logined-menu > ul > .bp5-popover-target  > .lmenu > a,
.logined-menu > ul > .bp5-popover-target  > .lmenu > li,
.logined-menu > ul > .lmenu > a,
.logined-menu > ul > .lmenu > li,
.list-element,
.menu-element
{
	display:flex;
	align-items: center;
	padding:15px 20px; 
	border-left:4px solid transparent;
	color:#AAA;
    width: 100%;
	cursor:pointer;
	text-decoration: none!important;
	transition: all 180ms ease-out;
}
.logined-menu > ul > .lmenu > a.active,
.logined-menu > ul > .lmenu > li.active,
.logined-menu > ul > .lmenu > a:hover,
.logined-menu > ul > .lmenu > li:hover,
.list-element:hover,
.list-element.active,
.menu-element:hover,
.menu-element.active
{
	color:#FFF;
	text-decoration:none!important;
}
.logined-menu > ul > .lmenu > a > span,
.logined-menu > ul > .lmenu > li > span,
.list-element > span,
.menu-element > span
{
	min-width:30px;
	height:17px;
    background-repeat: no-repeat;
    background-position-x: center;
	text-align:center;
}
.logined-menu > ul > .lmenu.exit
{
	padding: 15px 25px; 
	display: flex;
	align-items: center;
	color: #FFF!important;
	opacity: 0.5;
}
.logined-menu > ul > .lmenu.exit:hover
{
	opacity:1;
}
.logined-menu > ul > .lmenu.exit svg
{
	fill:#AAA;
}
.personal-menu__icon
{
	margin-right:30px!important;
	width:14px;
	height:14px;
}
.l-menu-has-children
{
	position:absolute;
	top:0;
	right:0px;
	height:100%;
	width:4px;
	background-color:#668aa5;
}
.iconic .logined-menu {
	left: -150px;
}
.menu-element > span {
	font-size: 14px;
}

.d-flex-menu,
.main-menu,
.main-menu>a
{
	display: flex;
    justify-content: stretch;
    justify-self: stretch;
    height: 100%; 
	margin-left: auto;
	margin-right: auto;
}
.main-menu>a
{
	align-items: center;
} 
.main-menu a
{
	padding-right: 0px;
	padding-left: 0px;
	color:#1b1515!important;
	opacity:0.8;
	border-bottom:4px solid transparent;
	-webkit-border-radius:0;
	border-radius:0;
	text-decoration:none!important;
}
.main-menu-filter-icon {
	padding: 5px;
	padding-right: 20px;
    padding-left: 20px;
	padding-top: .35rem;
}
.main-menu > a.active,
.main-menu > a:hover,
.main-menu > span > a.active,
.main-menu > span > a:hover,
.main-menu-filter-icon:hover
{
	background-color:#00000066!important;
	border-bottom:4px solid #FFC940;
	text-decoration:none!important;
	color:#FFFFFF!important;
	opacity:1;
}
.pe-footer-menu-container {
	display: flex;
	align-items: center;
	justify-content: center;
}
.pe-footer-menu-item-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	opacity:.8;
	&:hover {
		opacity:1;
	}
}
.pe-layout-main-footer-container {  
	min-width: 100%;
}
.stroke-dotted {
	display:flex;
	position:relative; 
}
.stroke-dotted::after {
	content:"";
	width:100%;
	background-image: linear-gradient(to right, #999 0,  #999 25%, transparent 26%, transparent 100%);
	background-position: bottom;
	background-size: 9px 3px;
	background-repeat: repeat-x;
	margin-left:12px;	 
}
.grayscale { 
	filter: grayscale(1);
} 
.pe-help-html-source {
	max-height: 560px;
	height: 100%;
	width: 100%;
	padding: 0 15px 50px 15px;
}
.pe-table-cell {
	max-width: 270px;
	overflow-x: hidden;
	word-break: normal;
	text-overflow: ellipsis;
}
.pe-table-cell-2 {
	max-width: 500px;
	overflow-x: hidden;
	word-break: normal;
	text-overflow: ellipsis;
}
.pe-pennant, .pe-small-pennant, .pe-narrow-pennant {
	position:relative;
	width: calc(100% - 76px);
	padding-left: 25px;
	color:#FFFFFF!important;
	height: 80px;
	display:flex;
	align-items:center;
	justify-content: start;
	background-color:green;
	transform: translateX(-2px);
	box-shadow: 0 1px 2px 0 rgba(0,0,0,.33);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.pe-pennant:before, .pe-small-pennant:before, .pe-narrow-pennant:before {
	content:"";
	position:absolute;
	top:0;
	right:-77px;
	border: 40px solid transparent; border-left: 40px solid green; border-top: 40px solid green;
}
.pe-pennant::after, .pe-small-pennant:after, .pe-narrow-pennant:after {
	content:"";
	position:absolute;
	bottom:0;
	right:-77px;
	border: 40px solid transparent; border-bottom: 40px solid green; border-left: 40px solid green;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,.33); 
}
.pe-small-pennant {
	height:40px;
}
.pe-narrow-pennant {
	height:22px;
}
.pe-small-pennant:before {
	right: -37px;
	border: 20px solid transparent; border-left: 20px solid green; border-top: 20px solid green;
}
.pe-small-pennant:after {
	right: -37px;
	border: 20px solid transparent; border-bottom: 20px solid green; border-left: 20px solid green;
}
.pe-narrow-pennant:before {
	right: -19px;
	border: 11px solid transparent; border-left: 11px solid green; border-top: 11px solid green;
}
.pe-narrow-pennant:after {
	right: -19px;
	border: 11px solid transparent; border-bottom: 11px solid green; border-left: 11px solid green;
}

.pe-layout-header-mobyle {
	position: fixed;
    top: 0;
    left: 0; 
    color: #EEE;
    // padding: 1rem 0;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: space-between;
	align-items: center;
	transition: transform 500ms ease-out;
	&.closing {
		transform: translateY(-100px);
	}
	.icon-unlogined.iconic i {
		color: #888888;
		margin-top: auto;
		margin-left: auto;
	} 
	
}
.pe-layout-header-menu-mobyle {	
	position:fixed;
	bottom:0;
	left:0;
	background-color: #181e24;
	color: #e4e4e48e;
	padding: 1rem 0;
	z-index:100;
    width: 100%;
	height: 75px;
    display: flex;
	align-items: center;
    justify-content: center;
	transition: transform 500ms ease-out;
	.mobyle-main-menu-link.active  {
		// transform: scale(1.2);
		color: #f3f3f3;
		.header-menu-icon > span {
			transform: scale(1.2);
		}
	}
	&.closing {
		transform: translateY( 100px );
	}
}
.pe-mobile-popover-menu {
	max-width: 260px;
	display: flex;
	flex-wrap: wrap;
	padding: 0;
}
.mobile-pagination-container {
	display: none!important;
}
.tab-container {
	height: calc(100% - 100px);
}
.pe-comment-input-form-container {
	 position: relative;
	 background-color:#181e2444;
}
.pe-like-elem {
	font-size: 26px;
	padding: 0 0 10px 0;
	opacity:.8;
}
.pe-like-elem:hover {
	opacity:1;
}
.pe-like-count {
	font-size: 11px;
	position:absolute;
	bottom: -2px;
	right:-2px;
}
.pe-w-100 {
	width: 100px;
}
.pe-h-100 {
	height: 100px;
}
.z-index-1
{
	z-index:  1!important;
}
.z-index-2
{
	z-index:  2!important;
}
.z-index-3
{
	z-index:  3!important;
}
.z-index-4
{
	z-index:  4!important;
}
.z-index-5
{
	z-index: 5!important;
}
.z-index-6
{
	z-index: 6!important;
}
.z-index-7
{
	z-index: 7!important;
}
.z-index-8
{
	z-index: 8!important;
}
.z-index-9
{
	z-index: 9!important;
}
.z-index-10
{
	z-index: 10!important;
}
.z-index-100
{
	z-index:  100!important;
}
.z-index-110
{
	z-index:  110!important;
}
.z-index-120
{
	z-index:  110!important;
}
.z-index-10000
{
	z-index:  10000!important;
}
.text-larger {
	font-size:1.1rem;
	line-height: 1.5;
}
.text-large {
	font-size: 1.2rem;
	line-height: 1.6;
}
.text-giant {
	font-size: 1.3rem;
	line-height: 1.6;
}
.thumb-tile-btn {
	background-color: #00000033;
	cursor:pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	min-height: 100%;
	border: 1px solid #777;
	margin: 1px;
	font-weight: 600;
}
.EmojiPickerReact {
	background-color: #0b1014;
	li.epr-emoji-category>.epr-emoji-category-label {
		background-color: #0b101491;
	}
	.epr-search-container input.epr-search {
		background-color: #181e24;
		border-radius: 3px;
	}
	.epr-body {
		&::-webkit-scrollbar 
		{
			width: 7px;
			position: absolute;
			left: 0;
		}
		&::-webkit-scrollbar-track 
		{
			background: transparent;
			position: absolute;
		}
		&::-webkit-scrollbar-thumb 
		{
			background-color: #00000070;
			border-radius: 5px;
		}
		&::-webkit-scrollbar 
		{
			width: 8px; 
			cursor:pointer;
		}
		&::-webkit-scrollbar-thumb 
		{
			background-color: #b7b3b3c3;
			border-radius: 2px; 
		}
	}
}

.danger-alarm { 
	animation-name: danger-alarm;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}
@keyframes danger-alarm {
	0% {
		background-color: transparent;
	}
	25% {
		background-color: #b11212;
	} 
	75% {
		background-color: #b11212;
	}
	100% {
		background-color: transparent;
	}
}

/**
*	RADIO
*/

input[type=radio].radio 
{
	vertical-align: top;
	width: 27px;
	height: 27px;
	margin: 0 3px 0 0;
}
input[type=radio].radio + label 
{
	cursor: pointer;
}
input[type=radio].radio:not(checked) 
{
	position: absolute;
	opacity: 0;
}
input[type=radio].radio:not(checked) + label 
{
	position: relative;
	padding: 0 44px 44px 0;
	margin:3px;
}
input[type=radio].radio + label:active:before ,
input[type=radio].radio:checked + label:before,
.my_button:active
{
    background: #730505;
	color:#FFF;
	-webkit-box-shadow: 
		-1px -1px 1px 0 rgba(0,0,0,1),   
		inset 1px 4px 8px 3px rgba(0,0,0,0.4),  
		1px 1px 1px 0 rgba(255,255,255,.75);
	box-shadow: 
		-1px -1px 1px 0 rgba(0,0,0,1),   
		inset 1px 4px 8px 3px rgba(0,0,0,0.4),  
		1px 1px 1px 0 rgba(255,255,255,.75);
}
input[type=radio].radio + label:active:before 
{
	background: #d20c0c;
}
input[type=radio].radio:not(checked) + label:before ,
.my_button
{
	content: attr(data-hint);
	position: absolute;
	top: -3px;
	left: 0;
	width: 44px;
	height: 44px;
	border-radius: 4px;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#edeff2+0,999999+100 */
	background: #edeff2; /* Old browsers */
	background: -moz-linear-gradient(top,  #edeff2 0%, #999999 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #edeff2 0%,#999999 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #edeff2 0%,#999999 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#edeff2', endColorstr='#999999',GradientType=0 ); /* IE6-9 */
	-webkit-box-shadow: 
		inset -1px -1px 1px 0 rgba(0,0,0,1), 
		1px 4px 6px 0 rgba(0,0,0, .25), 
		inset 1px 1px 1px 0 rgba(255,255,255,.5);
	box-shadow:
		inset -1px -1px 1px 0 rgba(0,0,0,1), 
		1px 4px 6px 0 rgba(0,0,0, .25), 
		inset 1px 1px 1px 0 rgba(255,255,255,.5);
	text-align:center;
	color:#444;
	font-size:17px;
	font-weight:900;
	line-height: 2.5;
}
input[type=radio].radio:not(checked) + label:after 
{
	content: '';
	
}
input[type=radio].radio:checked + label:after 
{
	opacity: 1;
}


input[type=radio]._radio 
{
	vertical-align: top;
	width: 17px;
	height: 17px;
	margin: 0 3px 0 0;
}
input[type=radio]._radio + label 
{
	cursor: pointer;
}
input[type=radio]._radio:not(checked) 
{
	position: absolute;
	opacity: 0;
}
input[type=radio]._radio:not(checked) + label 
{
	position: relative;
	padding: 7px 0 7px 35px;
}
input[type=radio]._radio:not(checked) + label:before 
{
	content: '';
	position: absolute;
	top: 7px;
	left: 0;
    width: 24px;
    height: 24px;
	border: 1px solid #697286;
	-webkit-border-radius: 22px;
	border-radius: 22px;
	background: #FFF;
}
input[type=radio]._radio:not(checked) + label:after 
{
    content: '';
    position: absolute;
    top: 12px;
    left: 5px;
    width: 14px;
    height: 14px;
    -webkit-border-radius: 14px;
    border-radius: 14px;
    background: #8ca0ce;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.5);
    opacity: 0;
    transition: all .2s;
}
input[type=radio]._radio:checked + label:after 
{
	opacity: 1;
}

input[type=radio]._radio.form-enabled + label:before 
{
	border: 2px solid #FF0000;
	color:#FF0000;
}
input[type=radio]._radio.form-enabled + label
{
	color:#FFAAAA;
}


input[type=radio].radio_full 
{
	vertical-align: top;
	width: 100%;
	height: 60px;
	margin: 0 3px 0 0;
}
input[type=radio].radio_full + label 
{
	cursor: pointer;
}
input[type=radio].radio_full:not(checked) 
{
	position: absolute;
	opacity: 0;
}
input[type=radio].radio_full:not(checked) + label 
{
	position: relative;
	padding: 0;
	margin:3px;
    height: 45px;
    width: 100%;
}
input[type=radio].radio_full:checked + label:before
{
    background:#4a535d;
	color:#FFF;
	-webkit-box-shadow: 
		-1px -1px 1px 0 rgba(0,0,0,1),   
		inset 1px 4px 8px 3px rgba(0,0,0,0.4),  
		1px 1px 1px 0 rgba(255,255,255,.75);
	box-shadow: 
		-1px -1px 1px 0 rgba(0,0,0,1),   
		inset 1px 4px 8px 3px rgba(0,0,0,0.4),  
		1px 1px 1px 0 rgba(255,255,255,.75);
}
input[type=radio].radio_full:not(checked) + label:before
{
	content: attr(data-hint);
	position: absolute;
	top: -3px;
	left: 0;
	width: 100%;
	height: 44px;
	border-radius: 8px;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#edeff2+0,999999+100 */
	background: #edeff2; /* Old browsers */
	background: -moz-linear-gradient(top,  #edeff2 0%, #999999 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #edeff2 0%,#999999 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #edeff2 0%,#999999 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#edeff2', endColorstr='#999999',GradientType=0 ); /* IE6-9 */
	-webkit-box-shadow: 
		inset -1px -1px 1px 0 rgba(0,0,0,1), 
		1px 4px 6px 0 rgba(0,0,0, .25), 
		inset 1px 1px 1px 0 rgba(255,255,255,.5);
	box-shadow:
		inset -1px -1px 1px 0 rgba(0,0,0,1), 
		1px 4px 6px 0 rgba(0,0,0, .25), 
		inset 1px 1px 1px 0 rgba(255,255,255,.5);
	text-align:left;
	color:#444;
	font-size:15px;
	font-weight:normal;
	line-height: 2.7;
	padding-left:45px;
}
input[type=radio].radio_full:not(checked) + label:after 
{
	content: '';
	
}
input[type=radio].radio_full:checked + label:after 
{
	opacity: 1;
}


input[type=checkbox]._checkbox:not(checked) 
{
  position: absolute;
  opacity: 0;
}
input[type=checkbox]._checkbox:not(checked) + label 
{
  position: relative; /* будем позиционировать псевдочекбокс относительно label */
  padding: 0 0 0 60px; /* оставляем слева от label место под псевдочекбокс */
}
/* Оформление первой части чекбокса в выключенном состоянии (фон). */
input[type=checkbox]._checkbox:not(checked) + label:before 
{
  content: '';
  position: absolute;
  top: -6px;
  left: -4px;
  width: 50px;
  height: 26px;
  border-radius: 13px;
  background: #CDD1DA;
  box-shadow: inset 0 2px 3px rgba(0,0,0,.2);
}
input[type=checkbox]._checkbox:not(checked) + label:after 
{
  content: '';
  position: absolute;
  top: -3px;
  left: -2px;
  width: 22px;
  height: 22px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 2px 5px rgba(0,0,0,.3);
  transition: all .2s; /* анимация, чтобы чекбокс переключался плавно */
}
/* Меняем фон чекбокса, когда он включен. */
input[type=checkbox]._checkbox:checked + label:before 
{
  background: #9FD468;
}
/* Сдвигаем переключатель чекбокса, когда он включен. */
input[type=checkbox]._checkbox:checked + label:after 
{
  left: 22px;
}
/* Показываем получение фокуса. */
input[type=checkbox]._checkbox:focus + label:before 
{
  #box-shadow: 0 0 0 3px rgba(255,255,0,.5);
}


input[type=checkbox].ganre_checkbox
{
	position: absolute;
	opacity: 0;
}
input[type=checkbox].ganre_checkbox:not(.checked) + label ,
input[type=checkbox].ganre_checkbox.checked + label, 
input[type=checkbox].ganre_checkbox:checked + label 
{
    position: relative;
    padding: 0 0 0 56px;
    height: 50px;
    vertical-align: middle;
    display: table-cell;
    margin-bottom: 2px;
}
/* Оформление первой части чекбокса в выключенном состоянии (фон). */
input[type=checkbox].ganre_checkbox:not(.checked) + label:before ,
input[type=checkbox].ganre_checkbox.checked + label:before, 
input[type=checkbox].ganre_checkbox:checked + label:before 
{
	content: '';
	position: absolute;
	top: -4px;
	left: 20px;
	width: 50px;
	height: 50px;
	border-radius: 3px;
	background: #CDD1DA;
	box-shadow: inset 0 2px 3px rgba(0,0,0,.2);
}
/* Меняем фон чекбокса, когда он включен. */
input[type=checkbox].ganre_checkbox.checked + label:before, 
input[type=checkbox].ganre_checkbox:checked + label:before 
{
	background: #9FD468;
}

input[type=checkbox].ganre_checkbox:not(.checked) + label img,
input[type=checkbox].ganre_checkbox.checked + label img,
input[type=checkbox].ganre_checkbox:checked + label img
{
	width:40px;
	height:40px;
	position:absolute;
	top:0px;
	left:25px;
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
	opacity:0.5;
	-webkit-transition: all 300ms ease-out;
	-moz-transition: all 300ms ease-out;
	-ms-transition: all 300ms ease-out;
	-o-transition: all 300ms ease-out;
	transition: all 300ms ease-out;
}
input[type=checkbox].ganre_checkbox.checked + label img,
input[type=checkbox].ganre_checkbox:checked + label img
{
	-webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
	opacity:1;
}
.pe-color-picker-example {
	width: 40px;
	height: 32px;
	margin : 5px  5px  5px  0;
	cursor: pointer;
	background-color:#EEE;
	border: 3px solid #ffffff;
	offset:1px solid #111;
	offset-position: 3px;
	opacity:.75;

	&:hover {
		opacity:1;
	}
}
.chrome-picker {
	box-shadow: none!important;
}
.menu-edit-item-btn {
	max-width: calc(100% - 33px);
	flex-grow:2;
	width: 100%;
}
.sdialog-control {
	position:absolute;
	top: 0;
	right: -32px; 
	display: flex;
	flex-direction: column; 
	.fas {
		font-size: 14px;
		color:#FFF;
	} 
	@media screen and (max-width: 560px) {
		right: 0;
    	flex-direction: row;
	}
}
.bp5-dialog.full {
	.sdialog-control {
		right: 0; 
	}
}
.pe-tab-btn {
	padding: 10px 13px;
	transition: 300ms all ease-out;
	color: #AAAAAA!important;
	&:hover {
		background-color: #00000022;
		color: #EFEFEF!important;
	}
	&.active {
		color:#FFFFFF!important;
	}
}
.layout-app-state {
	@media (max-width: 576px) {
		height: 100%!important;
	}
}
.mobyle-main-menu-link {
	position:relative;
	transition: transform 300ms ease-out, width 700ms ease-out, max-width 700ms ease-out;	
	// overflow: hidden;
	transform: translateY(-7px);
	&.opened {
		width: 70px;
	}
	&.closed {
		width: 0px;
		overflow-x: hidden;
		overflow-y:visible;
	}
	.pe-mobyle-under-menu-arrow {
		position: absolute;
		top: 6px;
		left: calc(50% - 12px);
		width: 14px;
		height: 8px;
		background-color: #bfd7f9;
		clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
	}
	.pe-mobyle-under-menu-label {
		position: absolute;
		bottom: -13px; 
		overflow: hidden;
		text-overflow: ellipsis;
		height: 12px;
		background-color: #4440;
		color: #EEE;
		font-size: 9px;
		justify-content: center;
		align-items: center;
		text-align: center;  
		width: 60px; 
		&::before {
			content: "";
			position:absolute;
			top:0;
			left: -9px;
			border-top-left-radius: 9px;
			width: 9px;
			height: 100%;
		}
		&::after {
			content: "";
			position:absolute;
			top:0;
			right: -9px;
			border-top-right-radius: 9px;
			background-color: #444;
			width: 9px;
			height: 100%;
		}
	}
	&:focus .pe-mobyle-under-menu-label {
		display: block;
	}	
}

@media (max-width: 576px) {	
	.layout-menu-left {
		
	}
}
@media (max-width: 760px)
{   
	.mobile-pagination-container {
		display: flex!important;
		min-width: 60px;
		margin-right: -20px;
		padding-left: 15px;
	}
	.layout-header-title {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 1.5rem;
		line-height: 1.0;
	}
	.layout-top-buffer {
		height:20px;
	}
    .iconic .header-menu-icon img {
		width:33px;
		height: 33px;
		transition: all 300ms ease-out;
		opacity: 0.5;
	}
	.mobyle-main-menu-link.active .iconic .header-menu-icon img {
		opacity:1;
	}
	.header-menu-element.iconic { 
		width: 70px;
		max-width: 70px; 
	}
	.main-menu 
	{
		flex-direction: column;
		width: 100%;		
		height: fit-content;
	}
	.main-menu a
	{
		width: 100%;
		height: auto;
		padding: 5px;
		padding-left: 24px;	
		color: #292f2b!important;
		border-bottom: 1px solid #FFFFFF20;
		border-left: 4px solid transparent;
	}
	.header-menu-icon 
	{
		width: 40px;
		font-size: 24px!important;
	}
	.layout-header-icon > svg
	{
		width:25px;
		height:25px;
	}
	.layout-header-link,
	.layout-header-bell,
	.layout-header-help
	{
		align-self: flex-start;
		padding: 5px 20px;
		align-items: center;
		display: flex;
		border-bottom: 1px solid #FFFFFF20;
		border-left: 4px solid transparent;
		width: 100%;
	}
	.layout-header-link > .layout-header-icon,
	.layout-header-bell > .layout-header-icon,
	.layout-header-help > .layout-header-icon
	{
		margin-right:10px;
	}
	.layout-header-link > span,
	.layout-header-bell > span,
	.layout-header-help > span
	{
		display:inline-block;
		color: #abccb6;
		
	}
	.layout-header-help.active
	{
		border-bottom: 1px solid #FFFFFF20;
		border-left: 4px solid #caff40;
		color: #FFF;
		text-decoration: none!important;
		background-color:transparent!important;
	}
	.icon-unlogined 
	{
		height: fit-content;
		// width: 100%;
		padding: 5px 10px;
	}
	.main-menu a.active,
	.layout-header-help.active > span
	{
		color: #bba56a!important;
	}
	
	
	.menu-popover
	{
		display:none;
	}
	
	.podmenu
	{
		align-self: flex-start;
		padding: 10px 20px 10px 80px;
		align-items: center;
		display: flex;
		border-bottom: 1px solid #FFFFFF20;
		border-left: 4px solid transparent;
		width: 100%;
		color: #abccb6;
	}
	
	.podmenu.active
	{
		border-bottom: 1px solid #FFFFFF20;
		border-left: 4px solid #caff40;
		color: #FFF;
		text-decoration: none!important;
		background-color:transparent!important;
	}
	.logined-menu > ul > .lmenu > a, 
	.logined-menu > ul > .lmenu > li, 
	.list-element
	{
		padding: 7px;
		padding-left: 25px;
	}
	.personal-menu__icon
	{
		margin-right:30px!important;
		width:25px;
		height:25px;
	}
	
	.layout-content
	{
		max-height: calc(100% - 75px);
	}
	.layout-content.opened
	{
		max-height: calc(100% - 0px);
	}
	.layout-content.pictogramm-include
	{
		display: grid;
		grid-template-columns: 38px calc(100% - 38px);
	}
	.layout-header 
	{
		flex-direction: column;					
		height: auto;
		--position: fixed;
		width: 100%;
		z-index: 12000;
		--background-color: #ffffff;
	}
}
img {
	max-width: 100%;
}
.pe-filter-container {
	position:relative;
	background-color: var(--drop-select-color);
	display: flex;
	.pe-filter-panel-container {
		width: 280px;
		overflow: hidden;
		display: flex;
		transition: 300ms width ease-out;
		&.closed {
			width:0;
		}
		input[type=text] {
			width: 100%;
			flex-grow: 1;
			
		}
	}
}
.pe-media-element-container {
	width: 100%;
	height: 100%;
	display:flex; 
	.pe-media-element-cont {
		padding: 20px;
		display:flex; 
		justify-content: center;
		align-items: center;
		flex-grow:1;
		.media-element {
			max-width: 90%;
			max-height: 80dvh;
		}
	}
	.pe-media-element-descr {
		width: 450px;
		padding: 0 15px;
		border-left: 1px solid #000;
	}
	@media (max-width: 760px) {
		& {
			flex-direction: column;
			.pe-media-element-descr {
				width: 100%;
				border-top: 1px solid #000;
			}
		}
	}
}
.pe-uploader-container {
	height: 300px;
	width: 100%;
	background-color: #00000022;
	border: 1px solid #00000033;
	margin: 4px 0;
	display: flex;
	position: relative;
	input[type=file]{
		width:100%;
		height: 100%;
		opacity:0;
		cursor: pointer;
	}
}
.transition-delay-0 {
	transition-delay: 0ms;
}
.transition-delay-100 {
	transition-delay: 100ms;
}
.transition-delay-200 {
	transition-delay: 200ms;
}
.transition-delay-300 {
	transition-delay: 300ms;
}
.transition-delay-400 {
	transition-delay: 400ms;
}
.transition-delay-500 {
	transition-delay: 500ms;
}
.transition-delay-600 {
	transition-delay: 600ms;
}
.transition-delay-700 {
	transition-delay: 700ms;
}
.transition-delay-800 {
	transition-delay: 800ms;
}
.transition-delay-900 {
	transition-delay: 900ms;
}
.transition-delay-1000 {
	transition-delay: 1000ms;
}
.transition-duration-100 {
	transition-duration: 100ms!important;
}
.transition-duration-200 {
	transition-duration: 200ms!important;
}
.transition-duration-300 {
	transition-duration: 300ms!important;
}
.transition-duration-400 {
	transition-duration: 400ms!important;
}
.transition-duration-500 {
	transition-duration: 500ms!important;
}
.transition-duration-600 {
	transition-duration: 600ms!important;
}
.transition-duration-700 {
	transition-duration: 700ms!important;
}
.transition-duration-800 {
	transition-duration: 800ms!important;
}
.transition-duration-900 {
	transition-duration: 900ms!important;
}
.transition-duration-1000 {
	transition-duration: 1000ms!important;
}
.transition-duration-1100 {
	transition-duration: 1100ms!important;
}
.transition-duration-1200 {
	transition-duration: 1200ms!important;
}
.transition-duration-1300 {
	transition-duration: 1300ms!important;
}
.transition-duration-1400 {
	transition-duration: 1400ms!important;
}
.transition-duration-1500 {
	transition-duration: 1500ms!important;
}
.transition-duration-1600 {
	transition-duration: 1600ms!important;
}
.transition-duration-1700 {
	transition-duration: 1700ms!important;
}
.transition-duration-1800 {
	transition-duration: 1800ms!important;
}
.transition-duration-1900 {
	transition-duration: 1900ms!important;
}
.transition-duration-2000 {
	transition-duration: 2000ms!important;
}
.transition-duration-2500 {
	transition-duration: 2500ms!important;
}
.transition-duration-3000 {
	transition-duration: 3000ms!important;
}
.transition-duration-3500 {
	transition-duration: 3500ms!important;
}
.transition-duration-4000 {
	transition-duration: 4000ms!important;
}
.transition-duration-4500 {
	transition-duration: 4500ms!important;
}
div, blockquote {
	& > .pe-surface {
		opacity: 0;
		transition: opacity 300ms ease-out;
		@media (max-width: 760px) {
			opacity: 1;
		}
		
	}
	&:hover > .pe-surface {
		opacity: 1;
	}
	& > div > .pe-surface2 {
		opacity: 0;
		transition: opacity 300ms ease-out;
		@media (max-width: 760px) {
			opacity: 1;
		}
		
	}
	&:hover > div > .pe-surface2 {
		opacity: 1;
	}
	& > div > div > .pe-surface3 {
		opacity: 0;
		transition: opacity 300ms ease-out;
		@media (max-width: 760px) {
			opacity: 1;
		}
		
	}
	&:hover > div > div > .pe-surface3 {
		opacity: 1;
	}

	& > div > div >  div > .pe-surface4 {
		opacity: 0;
		transition: opacity 300ms ease-out;
		@media (max-width: 760px) {
			opacity: 1;
		}
		
	}
	&:hover > div > div >  div > .pe-surface4 {
		opacity: 1;
	}
	
	& > div > div >  div >  div > .pe-surface5 {
		opacity: 0;
		transition: opacity 300ms ease-out;
		@media (max-width: 760px) {
			opacity: 1;
		}
		
	}
	&:hover > div > div >  div > div >  .pe-surface5 {
		opacity: 1;
	}
}

.minimal-colored
{ 
    width: 100%!important;
    max-width: 100%!important;
    background-color:#1a1e2413;
    padding: 0;
    margin: 0px;
}

.colored
{
	color: #FFF;
    width: 100%!important;
    max-width: 100%!important;
    background-color:#1a1e2491;
    padding: 0;
    margin: 0px;
}

.over-colored
{
	color: #FFF;
    width: 100%!important;
    max-width: 100%!important;
    background-color:#1a1e24a8;
    padding: 0;
    margin: 0px;
}

.pe-pennant-dark {
	background-color: darkgreen!important;
}
.pe-pennant-dark:after {
	border-left-color: darkgreen!important;
	border-bottom-color: darkgreen!important;
	background-color: darkgreen!important;
}
.pe-pennant-dark::before {
	border-left-color: darkgreen!important;
	border-top-color: darkgreen!important;
	background-color: darkgreen!important;
}
.pe-pumpkit {
	background-color: #e08f3e!important;
}
.pe-pumpkit:after {
	border-left-color: #e08f3e!important;
	border-bottom-color: #e08f3e!important;
}
.pe-pumpkit::before {
	border-left-color: #e08f3e!important;
	border-top-color: #e08f3e!important;
}
.pe-pumpkit-dark {
	background-color: #b36f2a!important;
}
.pe-pumpkit-dark:after {
	border-left-color: #b36f2a!important;
	border-bottom-color: #b36f2a!important;
}
.pe-pumpkit-dark::before {
	border-left-color: #b36f2a!important;
	border-top-color: #b36f2a!important;
}
.pe-pumpkit-mirk {
	background-color: #b35b04!important;
}
.pe-pumpkit-mirk:after {
	border-left-color: #b35b04!important;
	border-bottom-color: #b35b04!important;
}
.pe-pumpkit-mirk::before {
	border-left-color: #b35b04!important;
	border-top-color: #b35b04!important;
}
.pe-dark {
	background-color: #423c32;
}
.pe-dark:after {
	border-left-color: #423c32;
	border-bottom-color: #423c32;
}
.pe-dark::before {
	border-left-color: #423c32;
	border-top-color: #423c32;
}
.search-result {
	width: 120px;
	transition: 400ms width ease-out;
	overflow: hidden;
	text-overflow: ellipsis;
	font-style: italic;
	font-family: 'Times New Roman', Times, serif;
	opacity: .9;
	text-align: right;
	text-wrap: nowrap;
	white-space: nowrap;
	&-closed {
		width: 0;
		transition: 400ms width ease-out;
	}
}
.attachment-card {
	width: 150px!important;
	height: 150px!important;
	border-radius:0!important;
	margin: 10px; 
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 300ms all ease-out;
	cursor: pointer;
	background-size: cover;
	background-position: center; 
	.thum-cont,
	.thumbnail {
		border-radius:0!important;
		
	}
	.icon {
		width: 60px;
		height: 60px;
		background-size: cover;
		position: absolute;
		top: 70px;
		left: 70px;
		transform: translate(-30px, -30px);
		transition: 300ms all ease-out;
		transition-delay: 100ms;
		z-index: 3;
		opacity:0;
		pointer-events: none; 
		filter: invert(100%);
		
	}
	.muar {
		width: 160px!important;
		height: 160px!important;
		background-color: #292929;
		position: absolute;
		transition: 300ms all ease-out;
		transition-delay: 100ms;
		top:  0px;
		left: 0px;
		z-index:2;
		opacity:0;
		pointer-events: none;
	}
	._title {
		position: absolute;
		z-index: 10;
		transition: 100ms all ease-out;
		opacity: 1;
		height: 150px;
		width: 150px;
		top: 30px;
		left: 0px;
		transform: translateY(-30px);
		font-size: 9px;
		text-align: center;
		text-shadow: #000 1px ;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		pointer-events: none;
		transition: 400ms all ease-out;
		transition-delay: 300ms; 
		color:#FFF;
		background-image: radial-gradient(circle, rgba(0, 0, 0, 0.319) 10%, rgba(0, 0, 0, 0.084) 60%, rgba(0, 0, 0, 0) 120%);
		.icon-title {
			width: 13px;
			height: 13px;
			filter: invert(100%);
			background-size: cover;
		}
		&::before {
			content: "";
			position: absolute;
			top:0;
			left: 0;
		}
	}
	&.rutube_id .thumbnail,
	&.vk_video_id .thumbnail {
		background-color:#0b4b80;
	}	
	&.url .thum-cont,
	&.url .thumbnail {
		background-color:#2e9f28;
	}		
	&.media .thum-cont,
	&.media .thumbnail {
		background-color:#8c13a4;
	}		
	&.string .thum-cont,
	&.string .thumbnail {
		background-color:#a41313;
	}	
	&:hover {
		background-color:#00000000; 
		.icon{
			width: 34px;
			height: 34px;
			transform: translate(-17px, -17px);
			opacity: 1;
		} 
		._title {
			opacity: 0;
			transition: 100ms all ease-out;
			transition-delay: 0ms;
		}
		.muar { 
			opacity:.66;
		}	
		.thum-cont {  
			width: 160px!important;
		} 
	}
} 
.swiper-slide { 
    min-width: 130px; 
}