.day-night-container
{
    width: 50px;
    height: 40px;
    border-radius: 43px;
    /* border: 2px solid #0000002e; */
    background: rgb(137,199,249);
    background: linear-gradient(to top, rgba(137,199,249,1) 0%,rgba(54,131,193,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#89c7f9', endColorstr='#3683c1',GradientType=0 );
    cursor: pointer;
    position: relative;
    transition: all 400ms ease-out;
    box-shadow: 0 5px 12px 1px rgba(0,0,0,.55),
    inset 0 1px 1px 1px rgba(255,255,255,.15),
    inset 0 -1px 1px 1px rgba(0,0,0,.15);
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    transform: scale(.8);
}
.dnsw {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 32px;
    height: 32px;
    border-radius: 21px;
    background-color: #fde5a8;
    transition: box-shadow 300ms linear;
    transition: left 400ms ease-out;
    box-shadow: inset 0 -1px 1px 1px rgba(0,0,0,.5), 
    inset 0 1px 1px 1px rgba(255,255,255,.5),
    0 5px 6px 1px rgba(0,0,0,.25),
    0 2px 3px 1px rgba(0,0,0,.75);
    transform: scale(1);
}
.day-night-container:active .dnsw {
    --transform: scale(.97);
    box-shadow: inset 0 1px 1px 1px rgba(0,0,0,.5), 
    inset 0 -1px 1px 1px rgba(255,255,255,.5),
    inset 0 3px 5px 1px rgba(0,0,0,.25);
}
.day-night-container.night {
    background: rgb(2 14 26);
    background: linear-gradient(to top,rgb(0 0 0) 0%, rgb(2 14 26) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1c4c8e', endColorstr='#132609',GradientType=0 );
    
    transition: all 400ms ease-out;
}
.night .dnsw 
{
    left: 12px; 
} 
#sun-moon {
    position: absolute;
    top: 4px;
    left: 4px;
    transition: all 500ms ease-out;
    transition-delay: 300px;
}
#SVGID_1_ {
    transition: all 400ms ease-out; 
    box-shadow: inset 0 1px 1px 1px rgba(0,0,0,.5), 
    inset 0 -1px 1px 1px rgba(255,255,255,.5),
    inset 0 3px 5px 1px rgba(0,0,0,.25);
}
.day #SVGID_1_ {
    transform: translateX( 30px );
}
.night #SVGID_1_ {
    transform: translateX( 4px );
}
.dnsw-svg {
    clip-path:url(#SVGID_2_);
    fill:#FCE5A8;
}
.night .dnsw-svg { 
    transform: translateX( 0px );
}

.day  #sun-moon {
    transform: translateX(-22px);
}
.night  #sun-moon {
    transform: translateX(4px);
}