$clapanLeft: 330px;
$timerHeight: 40px;
$toposHeadHeight: 66.66%;
$toposFooterHeight: 33.33%;

.topos-first-head {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 1400px) {
        height: auto; 
    }
}
.topos-head-container {
    height: $toposHeadHeight;
    @media screen and (max-width: 1400px) {
        height: auto; 
    }
    .description {
        font-size:1.1rem;
        letter-spacing: 1px;
        color: #FFFFFF!important;
    }
    .topos-head-left {
        padding:0;
        min-height: 380px;
        @media screen and (max-width: 1400px) {
            min-width: 100% !important; 
        }
    }
    .topos-head-right {
        padding: 0;
        display:flex;
        flex-direction: column;
        height: 100%;
        @media screen and (max-width: 1400px) {
            min-width: 100% !important;
        }    
    }
}
.topos-footer-container {
    height: $toposFooterHeight;
    @media screen and (max-width: 1200px) {
        height: auto; 
    }
}
.topos-banner {
    position:relative;
    margin-top:1px;
    background-size:cover;
    background-position: center;
    border: 1px solid #000;
}
.topos-banner img {
    width:100%;
    opacity:0;
}
.topos-content {
    height: calc(100% - $timerHeight);
    @media screen and (max-width: 1200px) {
        height: 100%; 
    }
}
.topos-landscape-container {
    width: 100%;
    height: 100%;
    display: flex; 
    .topos-landscape-panel {
        width:0;
        flex-grow: 100;
        overflow-x: hidden;
        transition: all 500ms ease-out;
        &.open {
            min-width: 400px;
        }
        .landscape-fliend-btn {
            position:relative;
            padding: 10px;
            display: flex;
            overflow:hidden;
            justify-content: flex-start;
            .bp5-button-text {
                width: 100%;
            }
        }
        @media screen and (max-width: 1400px) {
            &.open {
                min-width: 300px; 
            } 
        }
        @media screen and (max-width: 1200px) {
            &.open {
                min-width: 225px; 
            } 
        }
        @media screen and (max-width: 992px) {
            &.open {
                min-width:25%; 
            } 
        }
        @media screen and (max-width: 820px) {
            &.open {
                min-width: 100%; 
            } 
        }
    }
    .topos-landscape-feed {
        flex-grow: 1; 
        width: 100%;
        transition: all 500ms ease-out;
        @media screen and (max-width: 820px) {
            &.open {
                width: 0; 
            } 
        }
    }
    .topos-landscape-search {
        margin:5px;
        display: flex;
    }
    
}
.vk-avatar {
    position:absolute;
    top: 5px;
    left: 0px;
    .avatar {
        background-color: #00000022;
        width: 35px;
        height: 35px;
    }
}
.avatar-tail {
    position: absolute;
    top: 0px;
    left: 26px;
    width: 20px;
    height: 20px;
    z-index: 1; 
    .card_path {
        fill: #FFFFFF;
    }
    .contour { 
        stroke: #77777711;
        stroke-width: 1.5px;
        fill: transparent;
    }
    .contour2 { 
        stroke: #77777722; 
        stroke-width: 1.5px;
        fill: transparent;
    }
}
.vk-post {
    border: 1px solid #77777722;
    display: flex;
    width: 100%;
    min-width:350px;
    flex-direction:column;
    padding: 0;
    margin:10px 10px 10px 45px;
    border-radius: 0 26px 26px 26px;
    overflow:hidden;
    position:relative;
    .head {
        //background-color: #00000008;
        //border-bottom: 1px solid #00000010;
        width: 100%;
        padding: 7px 12px;
        display: flex;
        align-items:center;
        .about {
            display: flex;
            flex-wrap: wrap;
            align-items:center;
        }
        .title {
            margin:0;
            padding:0;
            font-size: 1.2rem;
            font-weight:100;
        }
    }
    .content {
        flex-grow: 100;
        padding: 13px;
        font-size: 1rem;
        p {
            margin-bottom: 1.1rem;
        }
        .thumbnail {
            transition: all 500ms ease-out;
            max-height: 60vh;
            max-width: 100%; 
            margin: 20px auto; 
        }
    }
    .footer {        
        width: 100%;
        display: flex;
    }
}
.topos-banner-container  {
    width: 325px;
}
.topos-calendar-container {
    width:100%;
    height: var(--main-height); 
}
.topos-calendar-content { 
    min-width: 100%;
    height: 100%;
}
.topos-calendar-widgwt {
    display:flex;
    align-items:center;
    width:100%;
    background: #000000AA;
}
.topos-calendar-menu {
    display:flex;
    width:100%;
}
.topos-calendar-navlink {
    padding:5px 20px;
    text-transform: uppercase;
}
.topos-calendar-slider {
    margin: 0 45px 0 0;
    .bp5-slider-handle {
        width:1px;
        opacity:0;
    }
    .bp5-slider-label {
        transform: none;
        top: 8px;
        font-size: 17px;
    }   
    .bp5-slider-progress.bp5-intent-primary {
        background: rgb(60, 113, 205); 
    } 
    .bp5-slider-track {
        background: rgb(69, 82, 105);
    } 
    .bp5-slider-track {
        right: -0px !important; 
    }
    .bp5-slider-track, 
    .bp5-slider-progress {
        height: 40px;        
        top: -1px !important;
    } 
    .bp5-slider-label {
        color: #FFFFFF!important;
    }
}


.lazy .bp5-slider-progress.bp5-intent-primary { 
    transition: all 500ms linear;
}
.topos-map-content {
    width: 100%; 
    position:relative;
    display:flex;
    height: var(--main-height);
}
.topos-left-clapan {
    width: $clapanLeft;
    overflow: hidden;
    transition: 400ms all ease-out;
    @media screen and (max-width: 1200px) {
        width: 0; 
    }
}
.topos-content {
    width: calc( 100% - $clapanLeft);
    transition: 400ms all ease-out;
    @media screen and (max-width: 1200px) {
        width: 100%; 
    }
    position:relative;
}
.card-top-img {
    min-height: 200px;
    position:relative; 
    cursor:pointer;
    .card-text {
        color:#000;
    } 
    .card-title {
        color:#000;
    }
}
.card-top-img::before {
    content:"";
    width:100%;
    height:100%;
    background: linear-gradient(to bottom,  #ffffff00 25%, #ffffff 100%); 
    position:absolute;
    transition: all 400ms ease-out;
    z-index:1;

}
.card:hover .card-top-img::before {
    background: linear-gradient(to bottom,  #ffffff55 0%, #ffffff 100%);
}
.topos-dmap-dialog-menu {
    width: 0px;
    min-width: 300px;
    background-color: #181e24;

}
.topos-event-thumbnail {
    height: calc( 100% - 150px);
    min-height: 300px;
}
.topos-dmap-dialog-content {
    width:100%;
    flex-grow:100;
} 
.topos-placetype-label-icon {
    height: 26px;
    width: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 26px 0 0 26px;
    background-color: #FFFFFF77;
}
.topos-calendar-day-example { 
    background-position:center bottom; 
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
.topos-card-news {  
    transition: 200ms all ease-out;
    position: relative; 
    height: 150px; 
    display: block;
    .thum-cont {
        z-index: 2;
        border-radius: 6px 0 0 6px;
        width: 150px;
        height: 150px;
        transition: 200ms all ease-out; 
        position:absolute; 
        overflow:hidden;
        .thumbnail {
            transition: 200ms all ease-out; 
            width: 170px;
            height: 170px;
            transform: translate(-5px, -5px);
        }
    }
    &:hover .thum-cont {
        width: 155px; 
        height: 160px; 
        transform: translate(-5px, -5px);
        .thumbnail { 
            transform: translate(0px, 0px);
        }
    } 
    > .back {  
        border-radius: 6px;
        padding:0;
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        transition: 200ms all ease-out; 
    } 
    &:hover > .back  { 
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        transform: translate(-5px, -5px);
    } 
    .topos-card-news-content {
        padding-left: 150px;
        width: calc(100% - 0px);
    }
    @media screen and (max-width: 576px) {
        height: 300px;
        .thumbnail {
            z-index: 2;
            border-radius: 6px 0 0 6px;
            width: 100%;
            height: 150px;
            transition: 200ms all ease-out; 
            position:absolute;
        } 
        .topos-card-news-content {
            padding-left: 0px;
            padding-top: 160px;
            width: calc(100% - 0px);
        }
    }
}  
.topos-ad {
    width: 100%;
    display: flex;
    padding: 15px 10px; 
    position: relative;
    align-items: center;
    cursor: pointer;  
    @media screen and (min-width: 1400px) {
        padding: 5px 10px;
    }
    @media screen and (max-width: 1200px) {
        padding: 15px 10px; 
    }
    @media screen and (min-width: 2000px) {
        padding: 12px 10px;
    }
    .thumbnail {
        width:  58px;
        min-width: 58px;
        height: 58px;
        outline-offset: 5px;
        outline: 5px solid #6f828c;
        border: 1px solid #6f828c;
        margin-right:10px;
    }
    .content {
        flex-grow: 1;
        padding: 5px 12px;
        width: calc(100% - 60px);
        
    }
}
.vk-gallery {
    display: flex; 
    img.thumbnail {
        border: 1px solid #777;
        margin: 2px!important;
    }
}
.video-container {
    cursor:pointer;
    position:absolute;
    width: 100%;
    height: 100%;
    top:0;
    overflow: hidden;
    background-color: #5e515100;
    .thumbnail {
        background-size: 120%;
        transition: 400ms all ease-out;
        position: absolute;
        width: 120%;
        height: 120%;
        transform: translate(-10%, -10%);
        &::before {
            content: '';
            position:absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            background-color: #00000077;
            transition: 800ms all ease;
        } 
    }
    .video-icon {
        position: absolute;
        z-index:2;
        opacity:.75;
        top: 50%;
        left: 50%;
        transform: translate(-30px, -30px); 
        width:  50px;
        height: 50px;
        transition: 300ms all ease;
        & > path.tre {
            transition: 300ms all ease;  
        }
    }
    h4 {
        text-shadow: 1px 1px 1px #000000EE;
        transition: 400ms all ease-out;
    } 
    &:hover {        
        background-size: 125%;
        &::before {
            background-color: #00000044;
        }
        .video-icon {
            opacity:1;
            transform: scale(1.2) translate(-25px, -25px); 
            path.tre {
                fill:#8e452bee;
                transform: scale( .8 ) translateX( 13.0% ) translateY( 13.0% );
            }
        }
    }
    &.isPlay {
        .thumbnail {
            transform: translateY(-120%) translateX(-13%) rotate(-14deg);
            &::before {
                opacity:0;
            }
        }
        h4 {
            transform: translateY(200%) rotate(-14deg); 
        }
        .video-icon {
            opacity:0;
        }
    }
}

.date-label {
    background-color: #00000011;
    width: 75px;
    border-radius: 4px;
    border: 1px solid #66666666;
    font-size: 16px;
    .date-month-label {
        font-size:1em;
        text-align: center;
        padding: 0 10px;
        display: flex;
        align-items:center;
        justify-content:center;
        letter-spacing: 1px;
    }
    .date-day-label {
        font-size: 2.2em;
        text-align: center;
        font-weight: 900;
        background-color: #00000044;
        border-bottom: 1px solid #66666633;
        border-top: 1px solid #66666600;
        padding: 0 10px;
    }
    .date-year-label { 
        font-size: .7em;
        text-align: center;
        padding: 4px 10px;
        font-weight: 700; 
        letter-spacing: 2px;
    }
}
.goods-card {
    border-radius: 12px;
    position:relative;
    overflow:hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    .thumbnail {
        height:300px;
        position: relative;
        .price {
            position:absolute;
            top:5px;
            right: 5px;
            color: #FFF;
            background-color: #4e242e;
            padding: 3px 10px;
            z-index:2;
        }
        &:after {
            content: "";
            position: absolute;
            top:0;
            left:0;
            z-index:1;
            width:100%;
            height: 100%;
            background:#00000077;
            transition: 300ms all ease-out;
        }
    }
    &:hover {
        .thumbnail::after {
            background:#00000022;
        }
    }
}
.single-video-container {
    position:relative;
    &::after {
        content: "";
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height: 100%;
    }
    .youtube-cont {
        position:absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color:#00000000;
        .panel {
            position:absolute;
            bottom:-36px;
            width: 100%;
            height: 36px;
            min-height: 36px;
            background-color: #0c131c99;
            border-top: 1px solid #EEEEEE55;
            transition: 300ms all ease-out;
            transition-delay: 300ms;
            padding: 3px;
            display: flex;
            color: #FFFFFF;
            z-index: 102;
        } 
    }
    &:hover {
        .panel { 
            bottom: 0px;
        } 
    }
}
.pe-land-partners-widget-container {
    background-color: #293742;
    .pe-topos-partner-container {
        max-width: 90px;
        display: flex;
        font-size: 9px;
        flex-direction: column;
        color:#FFF;
        margin-right: 10px;
        margin-bottom: 10px;
        .thumbn {
            width: 80px;
            height: 80px;
            font-size: 9px;
            background-position: center;
            background-size:contain;
            background-repeat: no-repeat;
        }
    }
}