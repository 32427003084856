:root {
    --thumb-size: 24px;
    --thumb-color: transparent;
}

.multi-tag {
    padding:0;
    display: flex;
    margin:2px;
    &.round {
        border-radius: 20px;
        & > div { 
            &:first-of-type {
                padding: 3px 10px 3px 22px;
                border-radius: 20px 0 0 20px;
            }
            &:last-of-type {
                padding: 3px 22px 3px 10px;
                border-radius: 0 20px 20px 0;
            }
        }
    }
    & > div {
        padding: 3px 10px;
    }
    
}

input[type="range"].b-rage{ 
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    height: var(--thumb-size);
    background-color: transparent;
    pointer-events: none;
}

input[type="range"].b-rage::-webkit-slider-runnable-track{
    appearance: none;
    height: var(--thumb-size);
   
    
}
input[type="range"].b-rage::-moz-range-track{
    appearance: none;
    height: var(--thumb-size);
   
    
}
input[type="range"].b-rage::-ms-track{
    appearance: none;
    height: var(--thumb-size);
   
    
}
input[type="range"].b-rage::-webkit-slider-thumb{
    appearance: none;
    height:100px;
    width: var(--thumb-size);
    background-color: transparent;
    cursor: pointer;
    pointer-events: auto;

}
input[type="range"].b-rage::-moz-range-thumb{
    border: none;
    outline: none;
    appearance: none;
    height:100px;
    width: var(--thumb-size);
    cursor: pointer; 
    background-color: transparent;
    pointer-events: auto;
}
input[type="range"].b-rage::-ms-thumb{
    border: none;
    outline: none;
    appearance: none;
    height:100px;
    width: var(--thumb-size);
    cursor: pointer;

    background-color: var(--thumb-color);
    pointer-events: auto;
}
input[type="range"].b-rage:active::-webkit-slider-thumb{
    background-color: none;
}
input[type="range"].b-rage:focus::-moz-range-thumb {
    border: none;
    outline: none;
    background-color: transparent;
    color: white;
  }
input[type="range"].b-rage:active::-moz-range-thumb {
    border: none;
    outline: none;
    background-color: var(--thumb-color);
    
  }

.rt90{
    transform: rotate(90deg);
}
.pe-gallery-prev-btn {
    position:absolute;
    top: 100px;
    left: -20px;
    z-index: 2;
}
.pe-gallery-add-btn {
    background-color: #77777722;
    border: 1px solid #77777755;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & > div {
        width: 60px;
        height: 60px;
        background-color: #77777711;
        border: 1px solid #77777755;
        display: flex;
        align-items: center;
        justify-content: center; 
        border-radius: 60px;
        font-size: 32px;
    }
}