$error_color: rgb(110, 33, 56);
$warning_color: #8a773b;
$info_color: rgb(37, 102, 128);
$force_majeure_color: rgb(201, 5, 54);

.bg-error {
    background-color: $error_color!important;
}
.bg-warning {
    background-color: $warning_color!important;
}
.bg-info {
    background-color: $info_color!important;
}
.bg-force-majeure {
    background-color: $force_majeure_color!important;
}
.bp5-tag {
    user-select: none;
    &.error {
        background-color: $error_color!important;
        color: #FFF!important;
        &.bp5-minimal {
            background-color: rgba(106, 81, 88, 0.507)!important;
            //color: var(--force-contast-color)!important;
        }
    }
    &.warning {
        background-color: $warning_color!important;
        color: #FFF!important;
        &.bp5-minimal {
            background-color: #88816a7e!important;
            //color: var(--force-contast-color)!important;
        }
    }
    &.info {
        background-color: $info_color!important;
        color: #FFF!important;
        &.bp5-minimal {
            background-color: rgba(99, 119, 127, 0.479)!important;
            //color: var(--force-contast-color)!important;
        }
    }
    &.force-majeure {
        background-color: $force_majeure_color!important;
        color: #FFF!important;
        &.bp5-minimal {
            background-color: rgba(138, 107, 115, 0.534)!important;
            //color: var(--force-contast-color)!important;
        }
    }
}
.title-error {
    color: rgb(179, 26, 71);
}
.title-warning {
    color: #997a13;
}
.title-info {
    color: rgb(19, 114, 151);
}
.title-force-majeure {
    color: rgb(255, 0, 64);
} 
.log-dang-type-btn {
    width: 55px;
    height: 7px;
    cursor: pointer;
    margin-right: 2px;
    opacity: .33;
    &.dt-select {
        opacity: .8;
    }
    &:hover {
        opacity: 1;
    }
}
.pe-trash-log-container {
    max-height:900px;
    height: 100%;
}