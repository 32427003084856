.row.dat .bp5-tag-input {
	background: transparent!important;
	box-shadow: none!important;
	border-bottom: 1px solid #00000040 !important;
}
.row.dat .bp5-input-ghost,  
.row.dat .bp5-multi-select-tag-input-input {
	border-bottom: null;
}

.over-z-index {
	z-index: 14001;
}

.bp5-button.stretch > .bp5-button-text {
	width: 100%;
}
.bp5-editable-text {
	&::before {
		background-color: transparent!important;
		box-shadow: none!important;
		outline-color: transparent!important;
	}
	.bp5-editable-text-input {
		background-color: transparent!important;
		box-shadow: none!important;
		outline-color: transparent!important;
	}
	.bp5-editable-text-content { 
		box-shadow: none!important;
		outline-color: transparent!important;
	}
}

.input .bp5-input-group {
	width: calc(100% - 45px);
	height: 40px!important;
    min-height: 40px!important;
    line-height: 40px!important; 
}
.input .bp5-control-group {
	width:100%;
	height: 35px!important;
}
.input .bp5-input {
	height: 35px;
    min-height: 35px;
    line-height: 40px;
	border: none!important;
}
.no-card .bs5-card
{
	background-color: transparent!important;
	box-shadow:none!important;
}
.card-rpoute-menu > .bs5-button
{
	max-height: 30px;
    margin-bottom: 6px!important;
	background-color:#FFFFFFEE!important;
	background-image:none!important;
}
.collapse-selected .bs5-collapse-body
{
	overflow: visible!important;
    z-index: 10000;
    position: absolute;
    width: 100%;
}
.bp5-popover-target > *:first-child {
	outline: none!important; 
}
.bs5-editable-text::before
{
	border-color:transparent!important;
	box-shadow:none!important;
	outline:none!important;
	background:transparent!important;
}
.bs5-drawer { 
    transition: all 400ms ease-out;
}
.bs5-drawer-lose-btn-right
{
	position: absolute;
	right: -40px;
	top: 7px;
}
.bs5-tag
{
	margin-right:2px;
    margin-bottom: 2px;
	max-width:220px;
}
.main-menu>.bs5-popover-wrapper,
.main-menu>.bs5-popover-wrapper>.bs5-popover-target,
.main-menu>.bs5-popover-wrapper>.bs5-popover-target>a
{
	display: flex;
    justify-content: stretch;
    justify-self: stretch;
    height: 100%; 
	margin-left: auto;
}
.main-menu>.bs5-popover-wrapper>.bs5-popover-target>a
{
	align-items: center;
}
.main-menu .bs5-popover,
.main-menu .bs5-popover-content,
.bs5-menu
{
	color: #eef9f7;
    background-color: #091e27;
	-webkit-border-radius: 0;
	border-radius: 0;
	padding:0;
}
.main-menu .bs5-menu-item
{
    padding-top: 10px;
    padding-bottom: 10px;
}
.main-menu .bs5-transition-container
{
	---margin-top:-15px!important;
}
.main-menu .bs5-popover .bs5-popover-arrow
{
	top: -9px!important;
	display:none;
}
.main-menu .bs5-popover-content
{
	padding:0!important;
} 
.main-menu .bs5-button
{
	padding-right:10px;
	padding-left:10px;
	color:#1b1515!important;
	opacity:0.8;
	border-bottom:4px solid transparent;
	-webkit-border-radius:0;
	border-radius:0;
	text-decoration:none!important;
} 
.main-menu>.bs5-popover-wrapper>.bs5-popover-target>a.active,
.main-menu>.bs5-popover-wrapper>.bs5-popover-target>a:hover,
.main-menu .bs5-button.active,
.main-menu .bs5-button:hover
{
	background-color:#00000066!important;
	border-bottom:4px solid #FFC940;
	text-decoration:none!important;
	color:#FFFFFF!important;
	opacity:1;
}

.bs5-tab, 
.bs5-tab:focus, 
.bs5-tab:active 
{
    border-radius: 0;
    -webkit-box-shadow: none!important;
    box-shadow:  none!important;
	padding-left: 10px;
	padding-right: 10px;
	outline: none!important;
}
.bs5-btn-sm 
{	
    min-width: 20px;
    min-height: 20px;
}
.bs5-btn-sm svg
{
	width: 12px;
    height: 12px;
}

.category_card > .bs5-tabs.bs5-vertical > .bs5-tab-list
{
	width:170px;
	border-right:1px dotted #00000035;
	padding-right:5px;
}
.category_card  > .bs5-tabs.bs5-vertical > .bs5-tab-panel
{
	flex-grow:3;
}

.light .bs5-timepicker-input,
.light .bs5-html-select select,
.light .bs5-select select
{
	background:transparent;
	border:1px solid #FFFFFF25;
	border:1px solid transparent;
	margin-right:5px;
	margin-left:5px;
	--color:#FFF;
}
span.bs5-popover-target 
{
    display: block;
}


.bs5-card:after 
{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: -webkit-linear-gradient(left, #27ae60 0%, #27ae60 20%, #8e44ad 20%, #8e44ad 40%, #3498db 40%, #3498db 60%, #e74c3c 60%, #e74c3c 80%, #f1c40f 80%, #f1c40f 100%);
    background: -moz-linear-gradient(left, #27ae60 0%, #27ae60 20%, #8e44ad 20%, #8e44ad 40%, #3498db 40%, #3498db 60%, #e74c3c 60%, #e74c3c 80%, #f1c40f 80%, #f1c40f 100%);
    height: 3px;
	border-radius: 5px 5px 0 0;
}
.bs5-card.bs5-elevation-0
{
	--background-color:#EEE;
	color:#111;	
    margin: -.25rem;
}
/*  */
.form-control span.bs5-popover-target
{
	display:block;	
}
 
.input .bs5-tag
{
	color: #FFF!important;
}

.input .bs5-tag.bs5-minimal:not([class*="bs5-intent-"]) 
{
    color: #182026;
}
.bs5-numeric-input.input
{
	height:50px;
}
.bs5-numeric-input.input input
{
	width: 130px;
} 
.form-control .bs5-input
{
	background:transparent;
	border-radius:0;
	box-shadow: none;
	color:#FFF;
}
.dark .bs5-input
{ 
    color: #111;
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: 0 0 0 0 rgba(00,00,00,00);
    box-shadow: 0 0 0 0 rgba(00,00,00,00); 
}
.bs5-popover-content
{
	color:#111;
}
.bs5-popover-content
{
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
}
.tab-light-head > .bs5-tab-list
{ 
	background: #FFF;
    padding-bottom: 1px;
    width: 100%;
    margin-top: -3px; 
}

.icon-scalar-container > .bs5-tab-list > .bs5-tab
{
    width: 100px!important;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
	background-color: #FFFFFF!important;
    border: 1px solid #00000044;
    border-radius: 0!important;
    margin: 0;
}
.icon-scalar-container > .bs5-tab-list .bs5-tab[aria-selected="true"]
{
	background-color: #464545!important;
	color:#EEE!important;
}
.icon-scalar-container .bs5-elevation-0
{
	margin: 0;
	height: 100%;
}
.icon-scalar-container > .bs5-tab-panel
{
	margin: 0;
	padding: 0!important;
}

.css-style-for .bs5-input
{
	width: 55px;
    max-height: 100%;
    height: 100%;
}
.bs5-timepicker
{
	display: flex;
	margin: 12px;
	justify-content: center;
    position: relative;
}
.bs5-timepicker-input-row
{
	display: inline-flex;
    margin: 0;
	justify-content: space-around;
	z-index:2;
}
.bs5-timepicker-input 
{
    width: 22%;
    padding: 4px;
    text-align: center;
}
.bs5-timepicker .bs5-timepicker-arrow-row:first-of-type 
{
	position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.bs5-timepicker .bs5-timepicker-arrow-row:last-of-type 
{
	position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.bs5-html-select
{
	min-width: 65px;
}
.dark .bs5-tag
{
	color:#111!important;
}

.bp5-dialog
{
	width:auto!important;
	min-width:500px;
}
.main-menu .bs5-popover-content a.bs5-menu-item
{
	color:#EEE!important;
}
.bp5-dialog-container
{
	height:100%;
	overflow: hidden;
}
.b3-tab-panel-primary .bs5-tab:active,
.b3-tab-panel-primary .bs5-tab:focus
.b3-tab-panel-primary .bs5-tab
{
	-webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.b3-tab-panel-primary .bs5-tab
{	
    margin-right: 0px;
	border-radius: 7px 7px 0 0;	
    border-bottom: 1px solid #7ab8de;
}
.b3-tab-panel-primary .bs5-tab[aria-selected="true"]
{
	--background-color: #177ebe;
    --color: #FFF;
	border-left: 1px solid #7ab8de;
    border-right: 1px solid #7ab8de;
    border-top: 1px solid #7ab8de;
    border-bottom: 1px solid transparent;
}

.bp5-tab[aria-selected=true] {
    outline:none;
}
.bp5-toast-container {
	overflow: hidden!important;
}
.bp5-portal
{
	z-index: 100;
}
.bp5-dialog
{
	width:100%!important;
	position: relative;
	min-width:400px;
	max-width:800px;
	padding:0;
}
.transparent-dialog {
	background: #9db1ca2a!important;
    border: 1px solid #EEEEEE77;
    color: #EEE;
}

.bp5-elevation-1 
{
	padding-bottom: 1px;
    margin-bottom: 12px;
    box-shadow: none;
}
.bp5-input
{
	width:100%;
	padding:5px;
	max-height: 25px;
    text-align: center;
}
.bp5-input-group
{
	display:block;
}

.pe-room-btn .bp5-button-text
{ 
    margin-left:10px;
}
.pe-room-btn .bp5-icon 
{ 
    position: absolute;
    left: 12px;
}
.widget-translation-li .bp5-popover-target
{
	display:block;	
}
span.bp5-popover-target
{
	 position:relative;
}

.bp5-popover-wrapper
{
	 position:relative;
}
.bp5-tab-list
{
	flex-wrap: wrap;
}
.small-button .bp5-button-text
{
	font-size:12px;
}
.bs5-collapse .bs5-collapse-body
{
	overflow-x: hidden;
	width: 100%;
}
.bs5-overlay.bs5-overlay-inline.bs5-toast-container.bs5-toast-container-bottom.bs5-toast-container-right.bs5-toast-container-inline
{
	position:fixed;
}
.bp5-dialog-header .bs5-heading
{
	user-select:none;
}
.bp5-dialog {
	transition: 
		min-width 300ms ease-out, 
		max-width 300ms ease-out, 
		min-height 300ms ease-out, 
		max-height 300ms ease-out, 
		height 300ms ease-out!important;
	@media screen and (max-width: 560px) {
		max-width: 100%!important;
		min-height: 100%!important;
	}
}
.bp5-dialog.full
{
    min-width: 100vw!important;
    min-height: 100vh!important;
    border-radius: 0!important;
	background: #121920e0;
} 
.bp5-dialog.full .bp5-dialog-header
{
	border-radius: 0;
	background-color:#000; 
} 
.bp5-dialog.full .bp5-dialog-header .bs5-heading,
.bp5-dialog.full .bp5-dialog-header .bs5-icon
{  
	color:#FFF;
}
.bp5-dialog.large
{
	max-width: calc(100% - 100px);
	max-height: calc(100% - 100px);
	width:100%;
	height:100%;
} 
.bp5-dialog.large-eleganse
{
	max-width: calc(100% - 440px);
	max-height: calc(100% - 100px);
	width:100%;
	height:100%; 
	left: 140px;
} 
.bp5-dialog.normal
{
	max-width: 800px;
	min-height: 300px;
}
.bp5-dialog.minimal
{
	max-width:450px; 
}
.bp5-dialog.little
{
	max-width:450px;
	min-height: 300px;
}
.bp5-dialog.little2
{
	max-height:calc(100% - 20px);
}
.bp5-dialog.little2 .dialog-content
{
	overflow-y:auto;
    overflow-y: auto;
    margin: 0;
    border: none;
	max-height: calc(100vh - 140px);
}
.category_card > .bs5-tabs.bs5-vertical > .bs5-tab-list 
{
    width: 100%;
}
.bs5-tabs.bs5-vertical 
{
    flex-direction: column-reverse;
}
.bp5-dialog-header .bp5-heading {
    font-size: 20px;
}
.btn-stretch .bp5-button-text {
	width: 100%;
}
.btn-transparent {
	background: transparent;
}

.united-slider .bp5-slider-track {
	height: 16px!important;
    top: 0!important;
}
.united-slider .bp5-slider-progress {
	height: 16px!important; 
}
.united-slider .bp5-slider-progress.bp5-intent-primary { 
   	background-color: #609161!important;
}
.pe-gradient-handler-container {
	.bp5-slider {
		height: 6px!important;
		transform: translateY(-8px);
	}
	.bp5-slider-handle {
		background-color: red;
		clip-path: polygon(50% 100%, 0 0, 100% 0);
		width: 14px!important;
		height: 7px!important;
    	top: 4px!important;
		transform: translateX(-4px);
	}
}
.pe-large-height-dialog {
	max-height: calc(100% - 200px);
}


@media (max-width: 760px)
{
	.bs5-tab
	{
		text-align:center;
	}
	.bs5-tabs.bs5-vertical > .bs5-tab-list .bs5-tab 
	{
		border-radius: 0px;
		width: 100%;
		padding: 0 10px;
		background-color: #00000020!important;
	}
    .category_card > .bs5-tabs.bs5-vertical > .bs5-tab-list
	{
		border-right:none;
	}
	.bp5-dialog {
		width: 90%!important;
		min-width: 90%;
	}
	.bs5-tab-panel
	{
		padding:0!important;
		margin:0!important;
	}
    
	.main-menu>a.active, .main-menu>a:hover, .main-menu>.bs5-popover-wrapper>.bs5-popover-target>a.active, .main-menu>.bs5-popover-wrapper>.bs5-popover-target>a:hover, .main-menu .bs5-button.active, .main-menu .bs5-button:hover
	{
		border-bottom: 1px solid #FFFFFF20;
		border-left: 4px solid #caff40;
		color: #FFF;
		text-decoration: none!important;
		background-color:transparent!important;
	}
    .d-flex-menu, .main-menu, .main-menu>a, .main-menu>.bs5-popover-wrapper, .main-menu>.bs5-popover-wrapper>.bs5-popover-target, .main-menu>.bs5-popover-wrapper>.bs5-popover-target>a	
	{
		width:100%;
	}
	.main-menu>.bs5-popover-wrapper>.bs5-popover-target span>span
	{
		display:none;
	}    
	.bs5-tabs.bs5-vertical
	{
		display: block;
	} 
	.bs5-tabs.bs5-vertical .tabs-title
	{
		text-align:center;
		width: 100%;
	}
} 
@media (max-width: 540px)
{
    
	.bs5-tabs.bs5-vertical
	{
		display: block;
	} 
	.bs5-tabs.bs5-vertical .tabs-title
	{
		text-align:center;
		width: 100%;
	}
}
.low-animate-progress-bar {
	background: rgba(95, 107, 124, 0.1);
    // border-radius: 40px;
    display: block;
    height: 5px;
    overflow: hidden;
    position: relative;
    width: 100%;
	.bp5-progress-meter {
		// animation-duration: 9000ms!important;
		// animation-direction: normal!important;
		// background: linear-gradient(-45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%);
		// background-size: 30px 30px;
		// border-radius: 40px;
		height: 100%;
		position: absolute;
		transition: width 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
	}
}
.bp5-toast-container {
	z-index: 1000!important;
}